import { RequestError, AdminsService } from '@/services/admins.service';

const getters = {
  admins: state => state.admins,
  totalCount: state => state.totalCount,
  currentPage: state => state.currentPage
};

const actions = {
  async getAdmins({ commit, state }, params = {'page': state.currentPage}) {
    try {
      const response = await AdminsService.getAdmins(params);

      commit('storeAdmins', response.admins);
      commit('updateTotalTableCount', response.total_pages);

      return true;
    } catch (e) {
      if (e instanceof RequestError) {
        commit('requestError', {
          errorCode: e.errorCode,
          errorMessage: e.message,
        });
      }

      return false;
    }
  },

  async updateAdmin({ commit, dispatch }, payload) {
    try {
      await AdminsService.patchAdmin(payload);

      dispatch('getAdmins');

      return true;
    } catch (e) {
      if (e instanceof RequestError) {
        commit('requestError', {
          errorCode: e.errorCode,
          errorMessage: e.message,
        });
      }

      return false;
    }
  },

  async importAdmins({ commit, dispatch }, payload) {
    try {
      await AdminsService.importAdmins(payload);
      
      dispatch('getAdmins');

      return true;
    } catch (e) {
      if (e instanceof RequestError) {
        commit('requestError', {
          errorCode: e.errorCode,
          errorMessage: e.message,
        });
      }

      return false;
    }
  },

  async createAdmin({ commit, dispatch }, payload) {
    try {
      await AdminsService.createAdmin(payload);

      commit('requestError', {
        errorCode: '',
        errorMessage: 0,
      });

      dispatch('getAdmins');
      return true;
    } catch (e) {

      if (e instanceof RequestError) {
        commit('requestError', {
          errorCode: e.errorCode,
          errorMessage: e.message,
        });
      }

      return false;
    }
  },

  async deleteAdmin({ commit, dispatch }, id) {
    try {
      await AdminsService.deleteAdmin(id);

      dispatch('getAdmins');

      return true;
    } catch (e) {
      if (e instanceof RequestError) {
        commit('requestError', {
          errorCode: e.errorCode,
          errorMessage: e.message,
        });
      }

      return false;
    }
  },
};

const mutations = {
  toggleEditAdminFormDialog(state, value) {
    state.showEditAdminFormDialog = value;
  },
  storeAdmins(state, admins) {
    state.admins = admins;
  },
  requestError(state, { errorCode, errorMessage }) {
    state.requestErrorCode = errorCode;
    state.requestError = errorMessage;
  },
  updateCurrentPage(state, value) {
    state.currentPage = value;
  },
  updateTotalTableCount(state, value) {
    state.totalCount = value;
  },
};

const state = {
  totalCount: 0,
  currentPage: 1,
  admins: [],
  requestErrorCode: 0,
  requestError: '',
};

export const adminsModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

import { RequestError, ComunicationsService } from '@/services/comunications.service';

const getters = {
  comunications: state => state.comunications,
  currentPage: state => state.currentPage,
  totalCount: state => state.totalCount
};

const actions = {
  async getComunications({ commit, state }, params = {'page': state.currentPage}) {
    try {
      const response = await ComunicationsService.getComunications(params);
      
      commit('storeComunications', response.comunications);
      commit('updateTotalTableCount', response.total_pages);

      return true;
    } catch (e) {
      if (e instanceof RequestError) {
        commit('requestError', {
          errorCode: e.errorCode,
          errorMessage: e.message,
        });
      }

      return false;
    }
  },

  async updateComunication({ commit, dispatch }, payload) {
    try {
      await ComunicationsService.patchComunication(payload);

      dispatch('getComunications');

      return true;
    } catch (e) {
      if (e instanceof RequestError) {
        commit('requestError', {
          errorCode: e.errorCode,
          errorMessage: e.message,
        });
      }

      return false;
    }
  },

  async createComunication({ commit, dispatch }, payload) {
    try {
      await ComunicationsService.createComunication(payload);

      commit('requestError', {
        errorCode: '',
        errorMessage: 0,
      });

      dispatch('getComunications');
      return true;
    } catch (e) {

      if (e instanceof RequestError) {
        commit('requestError', {
          errorCode: e.errorCode,
          errorMessage: e.message,
        });
      }

      return false;
    }
  },

  async deleteComunication({ commit, dispatch }, id) {
    try {
      await ComunicationsService.deleteComunication(id);

      dispatch('getComunications');

      return true;
    } catch (e) {
      if (e instanceof RequestError) {
        commit('requestError', {
          errorCode: e.errorCode,
          errorMessage: e.message,
        });
      }

      return false;
    }
  },
};

const mutations = {
  storeComunications(state, comunications) {
    state.comunications = comunications;
  },
  requestError(state, { errorCode, errorMessage }) {
    state.requestErrorCode = errorCode;
    state.requestError = errorMessage;
  },
  updateCurrentPage(state, value) {
    state.currentPage = value;
  },
  updateTotalTableCount(state, value) {
    state.totalCount = value;
  },
};

const state = {
  totalCount: 0,
  currentPage: 1,
  comunications: [],
  requestErrorCode: 0,
  requestError: '',
};

export const comunicationsModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

import ApiService from '@/services/api.service';
import { TokenService, UserStorageService } from '@/services/storage.service';
import helpers from '@/lib/helpers';

class AuthenticationError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

class RequestError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

const ActivitiesService = {
  async getActivities(params) {
    const response = await ApiService.get('/activities', { params });
    return response.data;
  },

  async patchActivity(payload = {}) {
    try {
      const response = await ApiService.patchFormData(`/activities/${payload.get('activity[id]')}`, payload);

      return response.data;
    } catch (error) {
      helpers.showMessage(error.response, 'error');
      throw error;
    }
  },

  async createActivity(payload) {
    try {
      const response = await ApiService.postFormData('/activities', payload);

      return response.data;
    } catch (error) {
      helpers.showMessage(error.response, 'error');
      throw error;
    }
  },

  async deleteActivity(id) {
    try {
      await ApiService.post(`/activities/delete_all`, {
        id
      });

      return true;
    } catch (error) {
      helpers.showMessage(error.response, 'error');
      throw error;
    }
  },
};


export {
    ActivitiesService, AuthenticationError, RequestError,
};

import Vue from 'vue';

const helpers = {
  isNotOlderThan3Months(date) {
    const threeMonths = 90 * 24 * 60 * 60 * 1000;
    const targetTimestamp = Date.parse(date) + threeMonths;
    const today = new Date().getTime();

    return targetTimestamp > today;
  },

  showMessage(response, type) {
    let errors = [];
    let message = null;
    const { data } = response;

    if (!!data && data.errors && data.errors.length) {
      errors = data.errors;
    }

    if (type === 'error') {
      message = errors.join('<br>') || data.message;
    } else {
      message = data;
    }

    Vue.prototype.$message({
      dangerouslyUseHTMLString: true,
      message,
      type,
    });

    return errors;
  },
};

export default helpers;

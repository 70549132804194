export const communicationSenders = [
  {
    label: 'SNP',
    value: 'snp'
  },
  {
    label: 'FSLI',
    value: 'fsli'
  }
]

import { RequestError, ActivitiesService } from '@/services/activities.service';

const getters = {
  activities: state => state.activities,
  currentPage: state => state.currentPage,
  totalCount: state => state.totalCount
};

const actions = {
  async getActivities({ commit, state }, params = {'page': state.currentPage}) {
    try {
      const response = await ActivitiesService.getActivities(params);
      
      commit('storeActivities', response.activities);
      commit('updateTotalTableCount', response.total_pages);

      return true;
    } catch (e) {
      if (e instanceof RequestError) {
        commit('requestError', {
          errorCode: e.errorCode,
          errorMessage: e.message,
        });
      }

      return false;
    }
  },

  async updateActivity({ commit, dispatch }, payload) {
    try {
      await ActivitiesService.patchActivity(payload);

      dispatch('getActivities');

      return true;
    } catch (e) {
      if (e instanceof RequestError) {
        commit('requestError', {
          errorCode: e.errorCode,
          errorMessage: e.message,
        });
      }

      return false;
    }
  },

  async createActivity({ commit, dispatch }, payload) {
    try {
      await ActivitiesService.createActivity(payload);

      commit('requestError', {
        errorCode: '',
        errorMessage: 0,
      });

      dispatch('getActivities');
      return true;
    } catch (e) {

      if (e instanceof RequestError) {
        commit('requestError', {
          errorCode: e.errorCode,
          errorMessage: e.message,
        });
      }

      return false;
    }
  },

  async deleteActivity({ commit, dispatch }, id) {
    try {
      await ActivitiesService.deleteActivity(id);

      dispatch('getActivities');

      return true;
    } catch (e) {
      if (e instanceof RequestError) {
        commit('requestError', {
          errorCode: e.errorCode,
          errorMessage: e.message,
        });
      }

      return false;
    }
  },
};

const mutations = {
  storeActivities(state, activities) {
    state.activities = activities;
  },
  requestError(state, { errorCode, errorMessage }) {
    state.requestErrorCode = errorCode;
    state.requestError = errorMessage;
  },
  updateCurrentPage(state, value) {
    state.currentPage = value;
  },
  updateTotalTableCount(state, value) {
    state.totalCount = value;
  },
};

const state = {
  totalCount: 0,
  currentPage: 1,
  activities: [],
  requestErrorCode: 0,
  requestError: '',
};

export const activitiesModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template lang="pug">
  .app-container
    Sidebar
    .layout-container
      Navbar
      PageContainer
</template>

<script>
import PageContainer from '@/components/layouts/PageContainer';
import Sidebar from '@/components/layouts/Sidebar';
import Navbar from '@/components/layouts/Navbar';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'DashboardView',

  components: {
    PageContainer,
    Sidebar,
    Navbar,
  },

  computed: {
    ...mapGetters({
      loggedIn: 'authModule/loggedIn',
    }),
  },

  created() {
    if (this.loggedIn) {
      this.getCurrentAdmin();
    }
  },

  methods: {
    ...mapActions({
      getCurrentAdmin: 'authModule/getCurrentAdmin',
    }),
  }
};
</script>

<style scoped lang="scss">
  .app-container {
    .content-container {
      display: flex;
      flex-direction: column;
    }
  }
</style>

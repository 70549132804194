import ApiService from '@/services/api.service';
import { TokenService, AdminStorageService } from '@/services/storage.service';
import helpers from '@/lib/helpers';

class AuthenticationError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

class RequestError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

const AdminsService = {
  async getAdmins(params) {
    const response = await ApiService.get('/admins', { params });

    return response.data;
  },

  async patchAdmin(payload = {}) {
    try {
      const response = await ApiService.patch(`/admins/${payload['admin']['id']}`, payload);

      return response.data;
    } catch (error) {
      helpers.showMessage(error.response, 'error');
      throw error;
    }
  },

  async createAdmin(payload) {
    try {
      const response = await ApiService.post('/admins', payload);

      return response.data;
    } catch (error) {
      helpers.showMessage(error.response, 'error');
      throw error;
    }
  },

  async deleteAdmin(id) {
    try {
      await ApiService.post(`/admins/delete_all`, {
        id
      });
      return true;
    } catch (error) {
      helpers.showMessage(error.response, 'error');
    }
  },
};

export {
  AdminsService, AuthenticationError, RequestError,
};

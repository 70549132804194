<template lang="pug">
  el-row.mx-15(v-if="communication.id")
    el-col(:span="24")
      h1 {{ communication.title }}
    el-col(:span="24")
      img.main-img(v-if="mainImage" :src="mainImage")
    el-col(:span="24")
      p Data: {{ moment(communication.date).format('DD/MM/YYYY') }}
    el-col(:span="24")
      p Adresa de Contact: {{ communication.address }}
    el-col(:span="24")
      p Atasamente:
    el-col(:span="24")
      .attachment(
        v-for="attachment in communication.attachments"
        :key="attachment.id"
        @click="handlePreview(attachment)"
      )
        i.el-icon-document
        span {{ attachment.name }}
</template>

<script>
import { ComunicationsService } from '@/services/comunications.service';
import moment from 'moment';

export default {
  name: 'SharedComunicationView',

  data() {
    return {
      communication: {},
    };
  },

  computed: {
    mainImage() {
      return (
        this.communication &&
        this.communication.images[0] &&
        this.communication.images[0].url
      );
    },
  },

  async created() {
    const id = this.$route.params.id;
    this.communication = await ComunicationsService.getCommunication(id);
  },

  methods: {
    handlePreview(file) {
      if (file.id) {
        const link = document.createElement('a');
        link.href = file.url;
        link.download = file.name;
        link.click();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.mx-15 {
  margin-left: 15px;
  margin-right: 15px;
}

.main-img {
  max-width: 500px;
  width: 100%;
  max-height: 400px;
  object-fit: contain;
  object-position: 0;
}

.attachment {
  color: #edc731;
  background-color: #f0f2f5;
  width: fit-content;
  padding: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 3px;

  &:hover {
    color: #409eff;
  }

  i {
    color: #909399;
    padding-right: 5px;
  }
}
</style>

<template lang="pug">
  el-row.sidebar
    el-col
      el-menu.el-menu-vertical.side-custom(:router="true" :default-active="activeLink")
        el-menu-item(index="/users")
          .sidebar-cell
            img(:src="utilizatori" v-show="activeLink !== '/users'")
            img(:src="utilizatoriActive" v-show="activeLink === '/users'")
            p Utilizatori
        el-menu-item(index="/activities")
          .sidebar-cell
            img(:src="activitati" v-show="activeLink !== '/activities'")
            img(:src="activitatiActive" v-show="activeLink === '/activities'")
            p Activitati
        el-menu-item(index="/communications")
          .sidebar-cell
            img(:src="comunicate" v-show="activeLink !== '/communications'")
            img(:src="comunicateActive" v-show="activeLink === '/communications'")
            p Comunicate
        el-menu-item(index="/offers")
          .sidebar-cell
            img(:src="oferte" v-show="activeLink !== '/offers'")
            img(:src="oferteActive" v-show="activeLink === '/offers'")
            p Oferte
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'Sidebar',

  data() {
    return {
      isCollapsed: true,
      clicked: false,
      activitati: require("@/assets/img/activitati.svg"),
      comunicate: require("@/assets/img/comunicate.svg"),
      utilizatori: require("@/assets/img/utilizatori.svg"),
      oferte: require("@/assets/img/oferte.svg"),
      activitatiActive: require("@/assets/img/activitati-active.svg"),
      comunicateActive: require("@/assets/img/comunicate-active.svg"),
      utilizatoriActive: require("@/assets/img/utilizatori-active.svg"),
      oferteActive: require("@/assets/img/oferte-active.svg"),
      activeLink: null,
    };
  },

  watch: {
    $route (to, from) {
      this.activeLink = to.path;
    }
  },

  mounted() {
    this.activeLink = this.$route.path;
  },
};
</script>

<style scoped lang="scss">
  .sidebar {
    max-width: 91px;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .sidebar .el-menu-item.is-active {
    border-left-color: #EDC731;
    border-left-width: 4px;
    border-left-style: solid;
    p {
      color: #EDC731;
    }
  }

  .side-custom {
    padding-top: 152px;
  }

  .sidebar-cell {
    line-height: normal;
    text-align: center;
    color: #A2A7B0;
    font-family: 'PTSans' !important;
    padding-top: 10px;
  }

  .el-menu-item:hover {
    background-color: white !important;
  }

  .el-menu {
    border-right: 1px solid #D3E0F3;
  }
</style>

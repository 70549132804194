import axios from 'axios';
import store from '../store';
import router from 'router';
import { TokenService } from '@/services/storage.service';
import Qs from 'qs';

axios.interceptors.request.use(config => {

  config.paramsSerializer = params => {

    return Qs.stringify(params, {
      arrayFormat: 'brackets',
      encode: false
    });
  };

  return config;
});

const ApiService = {

  _401interceptor: null,

  init(baseURL) {
    axios.defaults.baseURL = baseURL;
  },

  setHeader() {
    axios.defaults.headers.common.Authorization = `Bearer ${TokenService.getToken()}`;
  },

  removeHeader() {
    axios.defaults.headers.common = {};
  },

  get(resource, data) {
    return axios.get(resource, data);
  },

  post(resource, data) {
    return axios.post(resource, data);
  },

  postFormData(resource, data) {
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    return axios.post(resource, data, config);
  },

  patchFormData(resource, data) {
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    return axios.patch(resource, data, config);
  },

  put(resource, data) {
    return axios.put(resource, data);
  },

  patch(resource, data) {
    return axios.patch(resource, data);
  },

  delete(resource, data) {
    return axios.delete(resource, data);
  },

  /**
     * Perform a custom Axios request.
     *
     * data is an object containing the following properties:
     *  - method
     *  - url
     *  - data ... request payload
     *  - auth (optional)
     *    - username
     *    - password
     * */
  customRequest(data) {
    return axios(data);
  },

  mount401Interceptor() {
    axios.interceptors.response.use(function (response) {
      return response
    }, function (error) {
      if (error.response.status === 401) {
        router.push('/login');
        throw error;
      }
      return Promise.reject(error)
    })
  },

  unmount401Interceptor() {
    // Eject the interceptor
    axios.interceptors.response.eject(this._401interceptor);
  },
};

export default ApiService;

import { RequestError, OffersService } from '@/services/offers.service';

const getters = {
  offers: state => state.offers,
  currentPage: state => state.currentPage,
  totalCount: state => state.totalCount
};

const actions = {
  async getOffers({ commit, state }, params = {'page': state.currentPage}) {
    try {
      const response = await OffersService.getOffers(params);
      
      commit('storeOffers', response.offers);
      commit('updateTotalTableCount', response.total_pages);

      return true;
    } catch (e) {
      if (e instanceof RequestError) {
        commit('requestError', {
          errorCode: e.errorCode,
          errorMessage: e.message,
        });
      }

      return false;
    }
  },

  async updateOffer({ commit, dispatch }, payload) {
    try {
      await OffersService.patchOffer(payload);
      dispatch('getOffers');

      return true;
    } catch (e) {
      if (e instanceof RequestError) {
        commit('requestError', {
          errorCode: e.errorCode,
          errorMessage: e.message,
        });
      }

      return false;
    }
  },

  async createOffer({ commit, dispatch }, payload) {
    try {
      await OffersService.createOffer(payload);

      commit('requestError', {
        errorCode: '',
        errorMessage: 0,
      });

      dispatch('getOffers');
      return true;
    } catch (e) {

      if (e instanceof RequestError) {
        commit('requestError', {
          errorCode: e.errorCode,
          errorMessage: e.message,
        });
      }

      return false;
    }
  },

  async deleteOffer({ commit, dispatch }, id) {
    try {
      await OffersService.deleteOffer(id);

      dispatch('getOffers');

      return true;
    } catch (e) {
      if (e instanceof RequestError) {
        commit('requestError', {
          errorCode: e.errorCode,
          errorMessage: e.message,
        });
      }

      return false;
    }
  },
};

const mutations = {
  storeOffers(state, offers) {
    state.offers = offers;
  },
  requestError(state, { errorCode, errorMessage }) {
    state.requestErrorCode = errorCode;
    state.requestError = errorMessage;
  },
  updateCurrentPage(state, value) {
    state.currentPage = value;
  },
  updateTotalTableCount(state, value) {
    state.totalCount = value;
  },
};

const state = {
  totalCount: 0,
  currentPage: 1,
  offers: [],
  requestErrorCode: 0,
  requestError: '',
};

export const offersModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

import { RequestError, AdminsContentService } from '@/services/adminsContent.service';
import { cloneDeep } from 'lodash';

const getters = {
  content: state => cloneDeep(state.content),
  
};

const actions = {
  async createContent({ commit }, payload) {
    try {
      await AdminsContentService.createAdminContent(payload);

      commit('requestError', {
        errorCode: '',
        errorMessage: 0,
      });

      return true;
    } catch (e) {

      if (e instanceof RequestError) {
        commit('requestError', {
          errorCode: e.errorCode,
          errorMessage: e.message,
        });
      }

      return false;
    }
  },

  async getContact({ commit, state }) {
    try {
      const response = await AdminsContentService.getAdminContent();

      commit('storeAdminContent', response);

      return true;
    } catch (e) {
      if (e instanceof RequestError) {
        commit('requestError', {
          errorCode: e.errorCode,
          errorMessage: e.message,
        });
      }

      return false;
    }
  }
};

const mutations = {
  toggleEditAdminFormDialog(state, value) {
    state.showEditAdminFormDialog = value;
  },
  storeAdminContent(state, content) {
    state.content = content;
  },
  requestError(state, { errorCode, errorMessage }) {
    state.requestErrorCode = errorCode;
    state.requestError = errorMessage;
  },
  updateCurrentPage(state, value) {
    state.currentPage = value;
  },
  updateTotalTableCount(state, value) {
    state.totalCount = value;
  },
};

const state = {
  totalCount: 0,
  content: {},
  currentPage: 1,
  admins: [],
  requestErrorCode: 0,
  requestError: '',
};

export const adminsContentModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

import { RequestError, UsersService } from '@/services/user.service';

const getters = {
  users: state => state.users,
  totalCount: state => state.totalCount,
  currentPage: state => state.currentPage,
  filters: state => state.filters,
  sortOptions: state => state.sortOptions
};

const actions = {
  async getUsers({ commit }) {
    try {
      let payload = {
        page: state.currentPage,
        filters: state.filters,
        sort: state.sortOptions
      }

      const response = await UsersService.getUsers(payload);

      commit('storeUsers', response.users);
      commit('updateTotalTableCount', response.total_pages);

      return true;
    } catch (e) {
      if (e instanceof RequestError) {
        commit('requestError', {
          errorCode: e.errorCode,
          errorMessage: e.message,
        });
      }
      return false;
    }
  },

  async updateUser({ commit, dispatch }, payload) {
    try {
      await UsersService.patchUser(payload);

      dispatch('getUsers');

      return true;
    } catch (e) {
      if (e instanceof RequestError) {
        commit('requestError', {
          errorCode: e.errorCode,
          errorMessage: e.message,
        });
      }

      return false;
    }
  },

  async importUsers({ commit, dispatch }, payload) {
    try {
      const response = await UsersService.importUsers(payload);

      dispatch('getUsers');

      return response;
    } catch (e) {
      if (e instanceof RequestError) {
        commit('requestError', {
          errorCode: e.errorCode,
          errorMessage: e.message,
        });
      }

      throw error;
    }
  },

  async createUser({ commit, dispatch }, payload) {
    try {
      await UsersService.createUser(payload);

      commit('requestError', {
        errorCode: '',
        errorMessage: 0,
      });

      dispatch('getUsers');
      return true;
    } catch (e) {

      if (e instanceof RequestError) {
        commit('requestError', {
          errorCode: e.errorCode,
          errorMessage: e.message,
        });
      }

      return false;
    }
  },

  async deleteUser({ commit, dispatch }, id) {
    try {
      await UsersService.deleteUser(id);

      dispatch('getUsers');

      return true;
    } catch (e) {
      if (e instanceof RequestError) {
        commit('requestError', {
          errorCode: e.errorCode,
          errorMessage: e.message,
        });
      }

      return false;
    }
  },
};

const mutations = {
  toggleEditUserFormDialog(state, value) {
    state.showEditUserFormDialog = value;
  },
  storeUsers(state, users) {
    state.users = users;
  },
  requestError(state, { errorCode, errorMessage }) {
    state.requestErrorCode = errorCode;
    state.requestError = errorMessage;
  },
  updateUserFilters(state, { key, value }) {
    state.filters[key] = value
  },
  updateSortOptions(state, { column, direction }) {
    state.sortOptions['column'] = column
    state.sortOptions['direction'] = direction
  },
  updateCurrentPage(state, value) {
    state.currentPage = value;
  },
  updateTotalTableCount(state, value) {
    state.totalCount = value;
  },
};

const state = {
  totalCount: 0,
  currentPage: 1,
  users: [],
  requestErrorCode: 0,
  requestError: '',
  filters: {},
  sortOptions: { column: 'order_by_full_name', direction: 'asc' }
};

export const usersModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

import { UserService } from '@/services/user.service';
import { TokenService, UserStorageService } from '@/services/storage.service';
import router from '@/router';

const getters = {
  loggedIn: state => (!!state.accessToken),

  authenticatedUser: state => state.authenticatedUser,

  authenticationErrorCode: state => state.authenticationErrorCode,

  authenticationError: state => state.authenticationError,

  authenticating: state => state.authenticating,
};

const actions = {
  async login({ commit }, { email, password }) {
    commit('loginRequest');

    try {
      const response = await UserService.login(email, password);
      commit('loginSuccess', { accessToken: response.tokens.accessToken });
      commit('updateAuthenticatedUser', JSON.parse(UserStorageService.getAuthenticatedUser()));

      return true;
    } catch (e) {
      return false;
    }
  },

  async reset({ commit }, { email }) {
    commit('reset');

    try {
      await UserService.reset(email);
      return true;
    } catch (e) {
      return false;
    }
  },

  async changePassword({ commit }, { password, resetPasswordToken}) {
    commit('reset');

    try {
      await UserService.changePassword(password, resetPasswordToken);
      return true;
    } catch (e) {
      return false;
    }
  },
  
  refreshToken({ commit, state }) {
    // If this is the first time the refreshToken has been called, make a request
    // otherwise return the same promise to the caller
    if (!state.refreshTokenPromise) {
      const p = UserService.refreshToken();
      commit('refreshTokenPromise', p);

      // Wait for the UserService.refreshToken() to resolve. On success set the token and
      // clear promise Clear the promise on error as well.
      p.then(
        (response) => {
          commit('refreshTokenPromise', null);
          commit('loginSuccess', response);
        },
        (error) => {
          commit('refreshTokenPromise', null);
        },
      );
    }

    return state.refreshTokenPromise;
  },

  async logout({ commit }) {
    try {
      await UserService.logout();

      commit('logoutSuccess');
      router.push('/login');
    } catch (e) {
      router.push('/login');
    }
  },

  async getCurrentAdmin({ commit }) {
    try {
      const response = await UserService.getCurrentAdmin();

      commit('updateAuthenticatedUser', response);
      return response;
    } catch (e) {
      throw e;
    }
  },
};

const mutations = {
  loginRequest(state) {
    state.authenticating = true;
    state.authenticationError = '';
    state.authenticationErrorCode = 0;
  },

  reset(state) {
    state.resetMailSent = true;
  },

  loginSuccess(state, data) {
    state.accessToken = data.accessToken;
    state.authenticating = false;
  },

  loginError(state, { errorCode, errorMessage }) {
    state.authenticating = false;
    state.authenticationErrorCode = errorCode;
    state.authenticationError = errorMessage;
  },

  logoutSuccess(state) {
    state.accessToken = '';
  },

  refreshTokenPromise(state, promise) {
    state.refreshTokenPromise = promise;
  },

  updateAuthenticatedUser(state, data) {
    state.authenticatedUser = data;
  },
};

const state = {
  resetMailSent: false,
  authenticating: false,
  accessToken: TokenService.getToken(),
  authenticationErrorCode: 0,
  authenticationError: '',
  refreshTokenPromise: null,
  authenticatedUser: JSON.parse(UserStorageService.getAuthenticatedUser()),
};

export const authModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template lang="pug">
  .admins
    p.title Administrare continut
    .admins-content-wrapper
      el-row(:justify="'center'")
        el-col(:span="11")
          p.title Contact
          el-form(:label-position='labelPosition' label-width='100px' :model='formContent')
            el-form-item(label='Adresa de contact').address-input
              el-input(v-model='formContent.address[index]' :key='index' v-for='(item, index) in formContent.address')
                i(v-if="!formContent.address[index]"
                  slot="suffix"
                  class="el-icon-circle-close c-pointer"
                  @click="handleRemove(index, 'address')"
                )
            p.add-new-field.float-right(@click='addNewField(formContent.address)') + Adauga camp nou
            el-form-item(label='Telefon')
              el-input(v-model='formContent.phones[index]' :key='index' v-for='(item, index) in formContent.phones')
                i(v-if="!formContent.phones[index]"
                  slot="suffix"
                  class="el-icon-circle-close c-pointer"
                  @click="handleRemove(index, 'phones')"
                )
            p.add-new-field(@click='addNewField(formContent.phones)') + Adauga camp nou
            el-form-item(label='Email')
              el-input(v-model='formContent.emails[index]' :key='index' v-for='(item, index) in formContent.emails')
                i(v-if="!formContent.emails[index]"
                  slot="suffix"
                  class="el-icon-circle-close c-pointer"
                  @click="handleRemove(index, 'emails')"
                )
            p.add-new-field(@click='addNewField(formContent.emails)') + Adauga camp nou
            el-form-item(label='Website')
              el-input(v-model='formContent.websites[index]' :key='index' v-for='(item, index) in formContent.websites')
                i(v-if="!formContent.websites[index]"
                  slot="suffix"
                  class="el-icon-circle-close c-pointer"
                  @click="handleRemove(index, 'websites')"
                )
            p.add-new-field(@click='addNewField(formContent.websites)') + Adauga camp nou

        el-col(:span="13") 
          p.title Istoric
          el-input(type='textarea' v-model="formContent.history")
      el-row(:justify="'center'")
        el-col(:span="24")
          .btn-add
            el-button(@click='addContent') Publica schimbarile



</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';

export default {
  name: 'AdminsContetView',
  components: {},
  data() {
    return {
      crudOperation: false,
      labelPosition: 'top',
      formContent: {
        address: [''],
        phones: [''],
        emails: [''],
        websites: [''],
        history: ''
      },
      dialogFormVisible: false,
      dialogFormUpdateVisible: false,
      dialogFormDeleteVisible: false,
      formLabelWidth: '120px'
    };
  },
  created() {
    this.getContact()
    setTimeout(() => {
      this.formContent.address = this.content.address;
      this.formContent.phones = this.content.phones;
      this.formContent.emails = this.content.emails;
      this.formContent.websites = this.content.websites;
      this.formContent.history = this.content.history;
    }, 500)
  },

  computed: {
    ...mapGetters({
      content: 'adminsContentModule/content',
    }),
  },

  methods: {
     ...mapActions({
      createContent: 'adminsContentModule/createContent',
      getContact:  'adminsContentModule/getContact'
    }),

    addNewField(fieldType) {
      fieldType.push('');
    },

    addContent(){
      this.createContent({ content: this.formContent });
    },

    handleRemove(index, filed) {
      this.formContent[filed].splice(index, 1);
    }
  },
};

</script>

<style scoped lang="scss">
  .admins {
    width: 100%;
    margin: 0px 68px 51px 68px;

    .c-pointer {
      cursor: pointer;
    }

    .admins-content-wrapper {
      background-color: white;
      padding: 15px 15px 29px;
      height: auto;
      width: 1214px;
      
      .el-dialog{
        width: 24% !important;
      }
    }
    
    .add-new-field {
      color: #E7C12C;
      margin-left: 23%;
      cursor: pointer;
    }

    .float-right{
      margin-left: 60% !important;
    }
    
    .upload-demo {
      max-width: 151px;;
      display: inline-block;
      
      .el-button {
        margin-left: 10%;
      }
    }
    
    .buttons-wrapper {
      width:100%;
      margin-top: 10px;
      margin-bottom: 60px;
      text-align: center;
      margin-left: 1%;
    }

    .select-all {
      color: #EDC731;
      font-family: PTSans;
      font-size: 14px;
      font-weight: 400;
      text-align: left
      width 50;
      height: 100%;
    }

    .delete-selections {
      border: 1px solid #A2A7B0;
      border-radius: 4px;
      height: 36px;
      float: left;
      display: inline-block;
      color: #A2A7B0;
      font-size: 14px;
      font-weight: 400;
      text-align: left;
    }

    .deselect-all {
      color: #C0C4CC;
      font-size: 14px;
      font-weight: 400;
      width: 50%;
      height: 100%;
      text-align: left;
    }

    .title {
      color: #02386D;
      font-family: 'PTSans-Bold';
      font-size: 35px;
      font-weight: 400;
      line-height: 42px;
      text-align: left;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .select-group {
      border-radius: 4px;
      width: 275px;
      height: 36px;
      display: inline-block;
      float: left;
    }

    .buttons-right {
      float: right;
      margin-right: 4%;
    }

    .add-btn {
      background-color: #EDC731;
      border-radius: 4px;
      width: 188px;
      height: 40px;
      color: #FFFFFF;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      text-align: center;
    }

    .import-btn {
      border: 1px solid #EDC731;
      border-radius: 4px;
      width: 151px;
      height: 40px;
      color: #EDC731;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      text-align: center;
    }

  .edit-btn {
    color: #1E7CD7;
    font-size: 14px;
    font-weight: 400;
    float: left;
    cursor: pointer;
  }

  .delete-btn {
    color: #FA5555;
    font-size: 14px;
    font-weight: 400;
    float: right;
    cursor: pointer;
  }
}
</style>

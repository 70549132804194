<template lang="pug">
    el-container.login-container
        el-card.login-card
            .login-logo
              img.logo(:src="logo")
            .login-title Resetare Parola
            el-form(ref="form" :model="form"  method="post")
                el-form-item(label='Parola')
                    el-input(
                      v-model="form.password"
                      name="password"
                      type="password"
                      placeholder="Parola")
                el-form-item(label='Confirmare Parola')
                    el-input(
                      v-model="form.password_confirmation"
                      name="password"
                      type="password"
                      placeholder="Noua Parola")
                el-form-item
                    el-button(@click='handleReset') Resetare Parola
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import helpers from '@/lib/helpers';

export default {
  name: 'PasswordConfirmationView',

  computed: {
    ...mapGetters('authModule', [
      'authenticationError',
    ]),
  },

  data() {
    return {
      form: {
        password: '',
        password_confirmation: '',
      },
      logo: require("@/assets/img/logo.svg")
    };
  },

  methods: {
    ...mapActions({
      changePassword: 'authModule/changePassword',
    }),

    async handleReset() {
      const { password, password_confirmation } = this.form;
      if (password !== password_confirmation) {
        const errorMessage = { data: { errors: ['Parolele introduse nu se potrivesc.'] } };
        helpers.showMessage(errorMessage, 'error');
        return;
      }

      const response = await this.changePassword({
        password,
        resetPasswordToken: this.$route.params.token,
      });

      if (response) {
        helpers.showMessage({ data: 'Parola a fost schimbata cu succes!' }, 'success');
        this.$router.push('/login');
      }
    },
  },
};
</script>

<style scoped lang="scss">
  .login-container{
    height: 100%;
    
    .forgot-password{
      color: #EDC731;
      font-family: 'PTSans-Regular';
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      text-align: right;
      text-decoration: underline;
    }

    .login-card {
      background-color: #FFFFFF;
      border-radius: 4px;
      box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.02);
      width: 422px;
      height: 461px;
      margin: auto;
    }

    .login-logo {
      text-align: center;
      padding-top: 20px;
      padding-bottom: 20px;

      img {
        margin: auto !important;
      }
    }

    .login-title {
      color: #333333;
      font-family: 'PTSans-Bold';
      font-size: 30px;
      font-weight: 400;
      line-height: 36px;
      text-align: center;
      padding-bottom: 20px;
    }
  }
</style>

import Vue from 'vue';
import Vuex from 'vuex';
import moment from 'moment';
import { authModule } from '@/store/modules/auth.module';
import { usersModule } from '@/store/modules/users.module';
import { activitiesModule } from '@/store/modules/activities.module';
import { offersModule } from '@/store/modules/offers.module';
import { comunicationsModule } from '@/store/modules/comunications.module';
import { adminsModule } from '@/store/modules/admins.module';
import { adminsContentModule } from '@/store/modules/adminsContent.module';


Vue.use(Vuex);
Vue.prototype.moment = moment

const debug = process.env.NODE_ENV !== 'production';

const store = new Vuex.Store({
  modules: {
    authModule,
    usersModule,
    activitiesModule,
    offersModule,
    comunicationsModule,
    adminsModule,
    adminsContentModule
  },
  strict: debug,
});

export default store;

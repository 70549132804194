import { render, staticRenderFns } from "./AdminsView.vue?vue&type=template&id=7a2df4aa&scoped=true&lang=pug&"
import script from "./AdminsView.vue?vue&type=script&lang=js&"
export * from "./AdminsView.vue?vue&type=script&lang=js&"
import style0 from "./AdminsView.vue?vue&type=style&index=0&id=7a2df4aa&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a2df4aa",
  null
  
)

export default component.exports
<template lang="pug">
  #page-container
    RouterView
</template>

<script>
export default {
  name: 'App',
};
</script>

<style scoped lang="scss">
  #page-container {
    height: 100%;
  }
</style>

<template lang="pug">
    el-container.reset-container
        el-card.reset-card
            .reset-logo
              img.logo(:src="logo")
            .reset-title Resetare Parola
            el-form(ref="form" :model="form"  method="post")
                el-form-item(label='Adresa de Email')
                    el-input(
                      v-model="form.email"
                      name="email"
                      placeholder="Email")
                el-form-item
                    el-button(@click='handleReset') Trimite link resetare
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import helpers from '@/lib/helpers';

export default {
  name: 'ResetPasswordView',

  computed: {
    ...mapGetters('authModule', [
      'authenticationError',
    ]),
  },

  data() {
    return {
      form: {
        email: ''
      },
      logo: require("@/assets/img/logo.svg")
    };
  },

  methods: {
    ...mapActions({
      reset: 'authModule/reset',
    }),

    async handleReset() {
      const { email } = this.form;
      const resposnse = await this.reset({
        email
      });

      if (resposnse) {
        helpers.showMessage(
          { 
            data: 'Email-ul pentru resetarea parolei a fost trimis! Urmati indicatiile din email pentru a va reseta parola!'
          },
          'success'
        );
        this.form.email = ''
      }
    },
  },
};
</script>

<style scoped lang="scss">
  .reset-container{
    height: 100%;
    
    .reset-card {
      background-color: #FFFFFF;
      border-radius: 4px;
      box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.02);
      width: 422px;
      height: 373px;
      margin: auto;
    }

    .reset-logo {
      text-align: center;
      padding-top: 20px;
      padding-bottom: 20px;

      img {
        margin: auto !important;
      }
    }

    .reset-title {
      color: #333333;
      font-family: 'PTSans-Bold';
      font-size: 30px;
      font-weight: 400;
      line-height: 36px;
      text-align: center;
      padding-bottom: 20px;
    }
  }
</style>

import ApiService from '@/services/api.service';
import { TokenService, UserStorageService } from '@/services/storage.service';
import helpers from '@/lib/helpers';

class AuthenticationError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

class RequestError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

const ComunicationsService = {
  async getComunications(params) {
    const response = await ApiService.get('/communications', { params });
    return response.data;
  },

  async patchComunication(payload = {}) {
    try {
      const response = await ApiService.patchFormData(`/communications/${payload.get('communication[id]')}`, payload);

      return response.data;
    } catch (error) {
      helpers.showMessage(error.response, 'error');
      throw error;
    }
  },

  async createComunication(payload) {
    try {
      const response = await ApiService.postFormData('/communications', payload);

      return response.data;
    } catch (error) {
      helpers.showMessage(error.response, 'error');
      throw error;
    }
  },

  async deleteComunication(id) {
    try {
      await ApiService.post(`/communications/delete_all`, {
        id
      });

      return true;
    } catch (error) {
      helpers.showMessage(error.response, 'error');
    }
  },

  async getCommunication(id) {
    try {
      const response = await ApiService.get(`/communications/${id}`);
      return response.data;
    } catch (error) {
      helpers.showMessage(error.response, 'error');
      throw error;
    }
  },
};


export {
    ComunicationsService, AuthenticationError, RequestError,
};

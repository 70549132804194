import ApiService from '@/services/api.service';
import { TokenService, UserStorageService } from '@/services/storage.service';
import helpers from '@/lib/helpers';

class AuthenticationError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

class RequestError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

const UserService = {
  /**
     * Login the user and store the access token to TokenService.
     *
     * @returns access_token
     * @throws AuthenticationError
     * */
  async login(email, password) {
    const requestData = {
      method: 'post',
      url: 'admins/login',
      data: {
        grant_type: 'password',
        email,
        password,
      },
    };

    try {
      const response = await ApiService.customRequest(requestData);

      TokenService.saveToken(response.data.tokens.access_token);
      TokenService.saveRefreshToken(response.data.tokens.refresh_token);

      UserStorageService.saveAuthenticatedUser(response.data.user);

      ApiService.setHeader();

      return response.data;
    } catch (error) {
      helpers.showMessage(error.response, 'error');
    }
  },

  async getCurrentAdmin(email, password) {
    const requestData = {
      method: 'get',
      url: 'admins/me'
    };

    try {
      const response = await ApiService.customRequest(requestData);
      UserStorageService.saveAuthenticatedUser(response.data);
      return response.data;
    } catch (error) {
      helpers.showMessage(error.response, 'error');
      throw error;
    }
  },

  async reset(email) {
    const requestData = {
      method: 'post',
      url: 'admins/reset_password',
      data: {
        grant_type: 'password',
        email
      },
    };

    try {
      const response = await ApiService.customRequest(requestData);
      ApiService.mount401Interceptor();

      return response.data;
    } catch (error) {
      helpers.showMessage(error.response, 'error');
      throw error;
    }
  },

  async changePassword(password, resetPasswordToken) {
    const requestData = {
      method: 'post',
      url: 'admins/change_password',
      data: {
        grant_type: 'password',
        password: password,
        reset_password_token: resetPasswordToken
      },
    };

    try {
      const response = await ApiService.customRequest(requestData);
      ApiService.mount401Interceptor();

      return response.data;
    } catch (error) {
      helpers.showMessage(error.response, 'error');
      throw error;
    }
  },

  validateToken() {
    var token = TokenService.getToken();
    ApiService.mount401Interceptor();
    return token !== null;
  },

  /**
     * Refresh the access token.
     * */
  async refreshToken() {
    const refreshToken = TokenService.getRefreshToken();

    const requestData = {
      method: 'post',
      url: '/o/token/',
      data: {
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
      },
    };

    try {
      const response = await ApiService.customRequest(requestData);

      TokenService.saveToken(response.data.access_token);
      TokenService.saveRefreshToken(response.data.refresh_token);
      // Update the header in ApiService
      ApiService.setHeader();

      return response.data.access_token;
    } catch (error) {
      helpers.showMessage(error.response, 'error');
    }
  },

  /**
     * Logout the current user by removing the token from storage.
     *
     * Will also remove `Authorization Bearer <token>` header from future requests.
     * */
  async logout(removeTokenFromDB = true) {
    if (removeTokenFromDB) {
      const requestData = {
        method: 'delete',
        url: 'admins/logout',
        data: {
          access_token: TokenService.getToken(),
        },
      };

      await ApiService.customRequest(requestData);
    }

    TokenService.removeToken();
    TokenService.removeRefreshToken();

    UserStorageService.removeAuthenticatedUser();

    ApiService.removeHeader();
    ApiService.unmount401Interceptor();
  },



  updateAuthenticatedUserRole(role_id, attribute) {
    const currentUser = JSON.parse(UserStorageService.getAuthenticatedUser());

    if (currentUser.role.id === role_id) {
      currentUser.role[attribute] = !currentUser.role[attribute];
      UserStorageService.saveAuthenticatedUser(currentUser);
    }
  },
};

const UsersService = {
  async getUsers(params) {
    const response = await ApiService.get('/admins/users', { params });

    return response.data;
  },

  async patchUser(payload = {}) {
    try {
      const response = await ApiService.patch(`/admins/update_user`, payload);

      return response.data;
    } catch (error) {
      helpers.showMessage(error.response, 'error');
      throw error;
    }
  },

  async importUsers(payload = {}) {
    try {
      const response = await ApiService.postFormData(`/admins/import_users`, payload);

      return response.data;
    } catch (error) {
      helpers.showMessage(error.response, 'error');
    }
  },

  async createUser(payload) {
    try {
      const response = await ApiService.post('/admins/create_user', payload);

      return response.data;
    } catch (error) {
      helpers.showMessage(error.response, 'error');
      throw error;
    }
  },

  async deleteUser(id) {
    try {
      await ApiService.delete(`/admins/delete_user`, {
        data: {
          user: {
            id: id
          }
        },
      });

      return true;
    } catch (error) {
      helpers.showMessage(error.response, 'error');
    }
  },
};

export {
  UserService, UsersService, AuthenticationError, RequestError,
};

import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes';
import { UserService } from '../services/user.service';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes,
});

router.beforeEach(async (to, from, next) => {
  const pageNeedsAuth = to.matched.some(record => record.meta.needsAuth);
  const isPublic = to.matched.some(record => record.meta.public);
  const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlyWhenLoggedOut);
  const loggedIn = await UserService.validateToken();

  
  if (!isPublic && !loggedIn && !['/reset_password', '/confirm_password'].includes(to['path'])) {
    return next({
      path: '/login',
      query: { redirect: to.fullPath }, // Store the full path to redirect the user to after login
    });
  }
  
  if (Object.keys(to.meta).length == 0){
    return next('/users');
  }

  return next();
});

export default router;

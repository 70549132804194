import Vue from 'vue';
import './assets';
import '@/components/element-ui/index';
import stringHelper from 'lodash/string';
import ApiService from '@/services/api.service';
import { TokenService } from '@/services/storage.service';
import router from '@/router';
import store from '@/store';

import App from './app.vue';

// Set the base URL of the API
ApiService.init('/api/v1');

// If token exists set header
if (TokenService.getToken()) {
  ApiService.setHeader();
  ApiService.mount401Interceptor();
}

Vue.filter('toStartCase', (string) => {
  if (!string) return '';
  return stringHelper.startCase(string);
});

document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App),
  });
});

<template lang="pug">
  el-container.page-container
    router-view
</template>

<script>

export default {
  name: 'PageContainer',
};
</script>

<style scoped lang="scss">
  .page-container{
    width: calc(100% - 91px);
    height: calc(100% - 99px);
    background-color: #F4F8F9;
    margin-right: 0;
    margin-left: auto;
  }
</style>

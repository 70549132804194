import Vue from 'vue';
import Router from 'vue-router';

import LoginView from '@/components/views/LoginView';
import UsersView from '@/components/views/UsersView';
import ComunicationsView from '@/components/views/ComunicationsView';
import ActivitiesView from '@/components/views/ActivitiesView';
import OffersView from '@/components/views/OffersView';
import DashboardView from '@/components/views/DashboardView';
import ResetPasswordView from '@/components/views/ResetPasswordView';
import SharedComunicationView from '@/components/views/SharedComunicationView';
import PasswordConfirmation from '@/components/views/PasswordConfirmation';
import AdminsView from '@/components/views/AdminsView';
import AdminsContentView from '@/components/views/AdminsContentView';

Vue.use(Router);

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      public: true, // Allow access to even if not logged in
      onlyWhenLoggedOut: true,
      needsAuth: false
    }
  },
  {
    path: '/reset_password',
    name: 'reset_password',
    component: ResetPasswordView,
    meta: {
      public: true, // Allow access to even if not logged in
      onlyWhenLoggedOut: true,
      needsAuth: false
    }
  },
  {
    path: '/communications/:id',
    name: 'communications',
    component: SharedComunicationView,
    meta: {
      public: true,
      onlyWhenLoggedOut: false,
      needsAuth: false
    }
  },
  {
    path: '/confirm_password/:token',
    name: 'confirm_password',
    component: PasswordConfirmation,
    meta: {
      public: true, // Allow access to even if not logged in
      onlyWhenLoggedOut: true,
      needsAuth: false
    }
  },
  {
    path: '/',
    component: DashboardView,
    children: [
      {
        path: '/users',
        name: 'users',
        component: UsersView,
        meta: {
          public: false, // Allow access to even if not logged in
          onlyWhenLoggedOut: false,
          needsAuth: true
        }
      },
      {
        path: '/communications',
        name: 'communications',
        component: ComunicationsView,
        meta: {
          public: false, // Allow access to even if not logged in
          onlyWhenLoggedOut: false,
          needsAuth: true
        }
      },
      {
        path: '/admins',
        name: 'admins',
        component: AdminsView,
        meta: {
          public: false, // Allow access to even if not logged in
          onlyWhenLoggedOut: false,
          needsAuth: true
        }
      },
      {
        path: '/admins_content',
        name: 'admins_content',
        component: AdminsContentView,
        meta: {
          public: false, // Allow access to even if not logged in
          onlyWhenLoggedOut: false,
          needsAuth: true
        }
      },
      {
        path: '/offers',
        name: 'offers',
        component: OffersView,
        meta: {
          public: false, // Allow access to even if not logged in
          onlyWhenLoggedOut: false,
          needsAuth: true
        }
      },
      {
        path: '/activities',
        name: 'activities',
        component: ActivitiesView,
        meta: {
          public: false, // Allow access to even if not logged in
          onlyWhenLoggedOut: false,
          needsAuth: true
        }
      }
    ]
  }
];

export default routes;

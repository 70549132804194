import ApiService from '@/services/api.service';
import helpers from '@/lib/helpers';

class AuthenticationError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

class RequestError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

const AdminsContentService = {
  async createAdminContent(payload) {
    try {
      const response = await ApiService.post('/contacts', payload);

      return response.data;
    } catch (error) {
      helpers.showMessage(error.response, 'error');
    }
  },

  async getAdminContent() {
    try {
      const response = await ApiService.get('/contacts');

      return response.data;
    } catch (error) {
      helpers.showMessage(error.response, 'error');
    }
  }
};

export {
    AdminsContentService, AuthenticationError, RequestError,
};

<template lang="pug">
  el-container.login-container
    el-card.login-card
      .login-logo
        img.logo(:src="logo")
      .login-title Autentificare
      el-form(ref="form" :model="form" method="post")
        el-form-item(label='Adresa de Email')
          el-input(
            v-model="form.email"
            name="email"
            placeholder="Email")
        el-form-item(label='Parola')
          el-input(
            v-model="form.password"
            name="password"
            type="password"
            placeholder="Parola"
            class="password-input"
            :show-password="true")
        .forgot-password(@click="handleForgot") Ti-ai uitat parola?
        el-form-item
          el-button(@click='handleLogin') Autentificare
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'LoginView',

  computed: {
    ...mapGetters('authModule', [
      'authenticationError',
    ]),
  },

  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      logo: require("@/assets/img/logo.svg")
    };
  },

  methods: {
    ...mapActions({
      login: 'authModule/login',
    }),

    handleForgot(){
      this.$router.push({ name: 'reset_password' });
    },
    
    async handleLogin() {
      const { email, password } = this.form;

      const response = await this.login({
        email,
        password,
      });

      if (response) {
        this.$router.push(this.$route.query.redirect || { name: 'users' });
      }
    },
  },
};
</script>

<style scoped lang="scss">
  .login-container {
    height: 100%;

    .forgot-password{
      color: #EDC731;
      font-family: 'PTSans-Regular';
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      text-align: right;
      text-decoration: underline;
      cursor: pointer;
    }

    .login-card {
      background-color: #FFFFFF;
      border-radius: 4px;
      box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.02);
      width: 422px;
      height: 461px;
      margin: auto;
    }

    .login-logo {
      text-align: center;
      padding-top: 20px;
      padding-bottom: 20px;

      img {
        margin: auto !important;
      }
    }

    .login-title {
      color: #333333;
      font-family: 'PTSans-Bold';
      font-size: 30px;
      font-weight: 400;
      line-height: 36px;
      text-align: center;
      padding-bottom: 20px;
    }
  }
</style>

<template lang="pug">
  .admins
    p.title Administratori
    .admin-wrapper
      el-dialog(title='Adauga Administrator' :visible.sync='dialogFormVisible').admins-dialog
        el-form(:label-position="labelPosition" label-width="100px" :model="formAdmin")
          el-form-item(label='Nume & Prenume' :label-width='formLabelWidth')
            el-input(v-model='formAdmin.full_name' autocomplete='off')
          el-form-item(label='Adresa de email' :label-width='formLabelWidth')
            el-input(v-model='formAdmin.email' autocomplete='off')
          el-form-item(label='Parola' :label-width='formLabelWidth')
            el-input(v-model='formAdmin.password' autocomplete='off')
          el-form-item(label='Rol' :label-width='formLabelWidth')
            el-radio(v-model="formAdmin.role" label="admin") Administrator
            el-radio(v-model="formAdmin.role" label="super_admin" ) Super-Admin
        .dialog-footer(slot='footer')
          .btn-add
            el-button(@click='addAdmin' :loading="loading") Adauga Administrator
          .btn-cancel
            el-button(type='primary' @click='dialogFormVisible = false' class="text-bold") Renunta
      el-dialog(title='Detalii Administrator' :visible.sync='dialogFormUpdateVisible').admins-dialog
        el-form(:label-position="labelPosition" label-width="100px" :model="formAdmin")
          el-form-item(label='Nume & Prenume' :label-width='formLabelWidth')
             el-input(v-model='formAdmin.full_name' autocomplete='off')
          el-form-item(label='Adresa de email' :label-width='formLabelWidth')
            el-input(v-model='formAdmin.email' autocomplete='off')
          el-form-item(label='Parola' :label-width='formLabelWidth')
            el-input(v-model='formAdmin.password' autocomplete='off')
          el-form-item(label='Rol' :label-width='formLabelWidth')
            el-radio(v-model="formAdmin.role" label="admin") Administrator
            el-radio(v-model="formAdmin.role" label="super_admin" ) Super-Admin
        .dialog-footer(slot='footer')
          .btn-add
            el-button(@click='updateAdminById' :loading="loading") Salveaza
          .btn-cancel
            el-button(type='primary' @click='dialogFormUpdateVisible = false' class="text-bold") Renunta
      el-dialog(title='Sterge Admin' :visible.sync='dialogFormDeleteVisible').admins-dialog
        .delete-text
          p Urmatoarea actiune va sterge toate datele despre utilizator.
          p Sunteti sigur ca doriti sa continuati?
        .dialog-footer(slot='footer')
          .btn-red
            el-button(@click='deleteCurrentAdmin') Sterge Administrator
          .btn-cancel
            el-button(type='primary' @click='dialogFormDeleteVisible = false' class="text-bold") Renunta

      .buttons-wrapper
        .buttons-left
          el-button-group.select-group
            el-button(type='primary' @click="toggleSelection(admins)").select-all Selecteaza tot
            el-button(type='primary' @click="toggleSelection()").deselect-all Deselecteaza tot
          el-button(@click='deleteSelected()' style='margin-left: 2%').delete-selections Sterge selectate
        .buttons-right
          el-button(@click="cleanAdminForm").add-btn Adauga Administrator
      el-table(ref='multipleTable' :data='this.admins' style='width: 100%' @current-change='handleRowChange' @selection-change='handleSelectionChange')
        el-table-column(type='selection' width='50')
        el-table-column(property='full_name' sortable label='Nume' width='320')
        el-table-column(property='email' label='Email' width='320')
        el-table-column(property='role' label='Rol' width='320' show-overflow-tooltip='')
        el-table-column(label="Actiuni" width='150')
          .action-buttons
            p.edit-btn(@click='handleEditBtnClick') Editeaza
            .divider
            p.delete-btn(@click='handleDeleteBtnClick') Sterge
      el-pagination(layout="prev, pager, next"
                    :total="totalCount"
                    :page-size="1"
                    :current-page="currentPage"
                    @next-click="handlePageClick"
                    @prev-click="handlePageClick"
                    @current-change="handlePageClick")

</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';

export default {
  name: 'AdminsView',
  components: {},
  data() {
    return {
      crudOperation: false,
      labelPosition: 'top',
      formAdmin: {
        full_name: '',
        email: '',
        role: '',
        password: ''
      },
      dialogFormVisible: false,
      dialogFormUpdateVisible: false,
      dialogFormDeleteVisible: false,
      formLabelWidth: '120px',
      loading: false,
    };
  },

  computed: {
    ...mapGetters({
      admins: 'adminsModule/admins',
      currentPage: 'adminsModule/currentPage',
      totalCount: 'adminsModule/totalCount',
      currentUser: 'authModule/authenticatedUser'
    }),
  },

  created() {
    this.updateCurrentPage(1);
    this.getAdmins();
  },

  methods: {
     ...mapActions({
      createAdmin: 'adminsModule/createAdmin',
      updateAdmin: 'adminsModule/updateAdmin',
      getAdmins:  'adminsModule/getAdmins',
      deleteAdmin: 'adminsModule/deleteAdmin',
      importAdmins: 'adminsModule/importAdmins',
      getCurrentAdmin: 'authModule/getCurrentAdmin'
    }),

    ...mapMutations('adminsModule', [
      'updateCurrentPage'
    ]),

    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row, true);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    deleteSelected() {
      var result = this.deleteAdmin(this.multipleSelection.map(a => a.id));
    },

    handleEditBtnClick() {
      this.dialogFormUpdateVisible = true;
      this.crudOperation = true;
    },

    handleDeleteBtnClick() {
      this.dialogFormDeleteVisible = true;
      this.crudOperation = true;
    },

    cleanAdminForm() {
      this.formAdmin.full_name = '';
      this.formAdmin.email = '';
      this.formAdmin.role = '';
      this.formAdmin.password = '';
      this.crudOperation = true;
      this.dialogFormVisible = true;
    },

    handlePageClick(page) {
      this.updateCurrentPage(page);
      this.getAdmins();
    },
    
    attachFiles(file) {
      const formData = new FormData();
      formData.append('admins[file][]', file.raw);

      var result = this.importAdmins(formData);
    },

    async addAdmin() {
      this.loading = true;
      const result = await this.createAdmin({ admin: this.formAdmin });
      if (result) {
        this.dialogFormVisible = false;
        this.crudOperation = false;
      }
      this.loading = false;
    },

    deleteCurrentAdmin() {
      var result = this.deleteAdmin(this.formAdmin.id);
      this.dialogFormDeleteVisible = false;
      this.crudOperation = false;
    },

    handleRowChange(val) {
      if (this.crudOperation == true) {
        this.formAdmin.id = val.id;
        this.formAdmin.full_name = val.full_name;
        this.formAdmin.email = val.email;
        this.formAdmin.role = val.role_updated;
        this.formAdmin.password = val.password;
      }
    },

    async updateAdminById() {
      this.loading = true;
      var result = await this.updateAdmin({ admin: this.formAdmin });
      if (result) {
        if (this.formAdmin.id === this.currentUser.id) {
          this.getCurrentAdmin();
        }
        this.dialogFormUpdateVisible = false;
        this.crudOperation = false;
      }
      this.loading = false;
    },

    handleClose(done) {
      done();
    }
  },
};

</script>

<style scoped lang="scss">
  .admins {
    width: 100%;
    margin: 0px 68px 51px 68px;

    .admin-wrapper {
      background-color: white;
      padding: 15px 15px 29px;
      height: auto;
      width: 1214px;
      
      .el-dialog{
        width: 24% !important;
      }
    }
    
    .upload-demo{
      max-width: 151px;;
      display: inline-block;
      
      .el-button {
        margin-left: 10%;
      }
    }
    
    .buttons-wrapper {
      width:100%;
      margin-top: 10px;
      margin-bottom: 60px;
      text-align: center;
      margin-left: 1%;
    }

    .select-all{
      color: #EDC731;
      font-family: PTSans;
      font-size: 14px;
      font-weight: 400;
      text-align: left
      width 50;
      height: 100%;
    }

    .delete-selections {
      border: 1px solid #A2A7B0;
      border-radius: 4px;
      height: 36px;
      float: left;
      display: inline-block;
      color: #A2A7B0;
      font-size: 14px;
      font-weight: 400;
      text-align: left;
    }

    .deselect-all{
      color: #C0C4CC;
      font-size: 14px;
      font-weight: 400;
      width: 50%;
      height: 100%;
      text-align: left;
    }

    .title {
      color: #02386D;
      font-family: 'PTSans-Bold';
      font-size: 35px;
      font-weight: 400;
      line-height: 42px;
      text-align: left;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .select-group {
      border-radius: 4px;
      width: 275px;
      height: 36px;
      display: inline-block;
      float: left;
    }

    .buttons-right{
      float: right;
      margin-right: 4%;
    }

    .add-btn {
      background-color: #EDC731;
      border-radius: 4px;
      width: 188px;
      height: 40px;
      color: #FFFFFF;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      text-align: center;
    }

    .import-btn {
      border: 1px solid #EDC731;
      border-radius: 4px;
      width: 151px;
      height: 40px;
      color: #EDC731;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      text-align: center;
    }

  .edit-btn{
    color: #1E7CD7;
    font-size: 14px;
    font-weight: 400;
    float: left;
    cursor: pointer;
  }

  .delete-btn{
    color: #FA5555;
    font-size: 14px;
    font-weight: 400;
    float: right;
    cursor: pointer;
  }
}
</style>

const TOKEN_KEY = 'access_token';
const REFRESH_TOKEN_KEY = 'refresh_token';
const AUTHENTICATED_USER = 'user';

/**
 * Manage the how Access Tokens are being stored and retreived from storage.
 *
 * Current implementation stores to localStorage. Local Storage should always be
 * accessed through this instance.
 * */
const TokenService = {
  getToken() {
    return localStorage.getItem(TOKEN_KEY);
  },

  saveToken(accessToken) {
    localStorage.setItem(TOKEN_KEY, accessToken);
  },

  removeToken() {
    localStorage.removeItem(TOKEN_KEY);
  },

  getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN_KEY);
  },

  saveRefreshToken(refreshToken) {
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  },

  removeRefreshToken() {
    localStorage.removeItem(REFRESH_TOKEN_KEY);
  },
};

const UserStorageService = {
  saveAuthenticatedUser(user) {
    localStorage.setItem(AUTHENTICATED_USER, JSON.stringify(user));
  },

  getAuthenticatedUser() {
    return localStorage.getItem(AUTHENTICATED_USER);
  },

  removeAuthenticatedUser() {
    localStorage.removeItem(AUTHENTICATED_USER);
  },
};

export { TokenService, UserStorageService };

<template lang="pug">
  el-menu.navbar(mode='horizontal')
    el-menu-item(index='1')
      img.logo(:src="logo")
    .login
      span
        img.avatar(:src="user")
      el-submenu.login-btn(index='2')
        template(slot='title') {{ currentUser.full_name }}
        el-menu-item(v-if="this.currentUser.role == 'Super-Admin'" @click='handleAdmins') Administratori
        el-menu-item.pre-formatted(v-if="this.currentUser.role == 'Super-Admin'" @click='handleContact') {{ title_content }}
        el-menu-item.red(@click='handleLogout') Deconectare
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';

export default {
  name: 'Navbar',
  data() {
    return {
      activeIndex: '1',
      user: require("@/assets/img/user.svg"),
      logo: require("@/assets/img/logo.svg"),
      title_content: "Administreaza\nContinut"
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'authModule/authenticatedUser'
    }),

    activeLink() {
      return this.$route.name;
    },
  },

  methods: {
    
    handleLogout() {
      this.$store.dispatch('authModule/logout', null, { root: true });
    },

    handleAdmins(){
      this.$router.push({ name: 'admins' });
    },

    handleContact(){
      this.$router.push({ name: 'admins_content' });
    }
  },
};
</script>
<style lang="scss">
  .navbar{
    max-height: 91px;
    height: 100%;
  }
  
  .login {
    float: right;
    height: 100%;
    outline: none;
  }

  .avatar {
    float: left;
  }

  .login-btn {
    float: right;
  }

  .el-menu-item, .el-submenu, .el-submenu__title{
    height: 100% !important;
  }

  .el-menu-item{
    text-align: center;
    padding-top: 10px !important;
  }

  .el-submenu__icon-arrow{
    position: initial !important;
    margin-left: 5px;
    top: 0 !important;
    margin-top: 0 !important;

  }

  .avatar{
    width: 16.67px;
    height: 20px;
  }

  .login span {
    padding-top: 38px;
    display: inline-block;
  }

  .el-submenu__title:hover {
    background-color: white !important;
  }

  .el-submenu__title {
    font-size: 16px;
    padding-top: 20px;
    padding-left: 9px;
    color: #02386D
  }

  .el-menu--popup {
    min-width: 151px;
    min-height: 147px;
    top: -27px !important;
    right: 18px;
    color: #1C1C1C;
    font-weight: 400;
    line-height: 17px;
    font-size: 14px;
  }

  .red{
    color: red !important;
  }

  .pre-formatted {
    white-space: pre;
    line-height: 16px !important;
  }

  .el-menu--horizontal .el-menu .el-menu-item {
    padding-top: 5%;
    padding-left: 22px;
    color: #1C1C1C;
  }

  .el-menu.el-menu--horizontal{
    border-bottom: 1px solid #D3E0F3 !important;
  }
</style>


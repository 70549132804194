<template lang="pug">
  .comunications
    p.title Comunicate
    .comunications-wrapper
      el-dialog.comunications-dialog(title='Adauga Comunicat' :visible.sync='dialogFormVisible')
        el-form(:label-position="labelPosition" label-width="100px" :model="formComunication")
          el-form-item(label='Titlu' :label-width='formLabelWidth').form-title
            el-input(v-model='formComunication.title' placeholder="Titlu comunicat" autocomplete='off')
          el-form-item(label='Adresa de contact' :label-width='formLabelWidth').form-title
            el-input(v-model='formComunication.address' placeholder="Adresa de contact" autocomplete='off')
          el-form-item(label='Expeditor' :label-width='formLabelWidth').form-title
            el-radio-group(v-model="formComunication.sender")
              el-radio(
                v-for="sender in communicationSenders"
                :label="sender.value"
              ) {{ sender.label }}
          el-form-item.form-title(label='Grupuri')
            el-select(v-model='formComunication.group')
              el-option(
                v-for="group in communicationGroups"
                :value="group"
              )
          .date-attachments
            el-form-item(label='Data' :label-width='formLabelWidth').form-date
              el-date-picker(type='date' placeholder='ZZ/LL/AAAA' v-model='formComunication.date')
            el-form-item(label='Atasamente (jpeg, jpg, png, pdf)' :label-width='formLabelWidth')
              el-upload.upload-demo.upload-btn(
                action="#"
                :multiple="true"
                :auto-upload="false"
                :file-list="attachments"
                :on-change="attachFiles"
                :before-remove="handleRemove"
                accept="image/png, image/jpeg, image/jpg, application/pdf")
                el-button Upload
        .dialog-footer(slot='footer')
          .btn-cancel
            el-button(type='primary' @click='dialogFormVisible = false' class="text-bold") Renunta
          .btn-add
            el-button(@click='addComunication' :loading="loading") Adauga Comunicat

      el-dialog.comunications-dialog(title='Detalii Comunicat' :visible.sync='dialogFormUpdateVisible' @close="handleClose")
        el-form(:label-position="labelPosition" label-width="100px" :model="formComunication")
          el-form-item(label='Titlu' :label-width='formLabelWidth').form-title
            el-input(v-model='formComunication.title' placeholder="Titlu comunicat" autocomplete='off')
          el-form-item(label='Adresa de contact' :label-width='formLabelWidth').form-title
            el-input(v-model='formComunication.address' placeholder="Adresa de contact" autocomplete='off')
          .date-attachments
            el-form-item(label='Data' :label-width='formLabelWidth').form-date
              el-date-picker(type='date' placeholder='ZZ/LL/AAAA' v-model='formComunication.date')
            el-form-item(label='Atasamente (jpeg, jpg, png, pdf)' :label-width='formLabelWidth')
              el-upload.upload-demo.upload-btn(
                action="#"
                :multiple="true"
                :auto-upload="false"
                :file-list="attachmentsForUpdate"
                :on-change="attachFiles"
                :before-remove="handleRemoveUpdate"
                :on-preview="handlePreview"
                accept="image/png, image/jpeg, image/jpg, application/pdf")
                el-button Upload
        .dialog-footer(slot='footer')
          .btn-cancel
            el-button(type='primary' @click='dialogFormUpdateVisible = false' class="text-bold") Renunta
          .btn-add
            el-button(@click='updateCurrentComunication' :loading="loading") Modifica Comunicat

      el-dialog(title='Sterge Comunicat' :visible.sync='dialogFormDeleteVisible').users-dialog
        .delete-text
          p Urmatoarea actiune va sterge toate datele despre comunicat.
          p Sunteti sigur ca doriti sa continuati?
        .dialog-footer(slot='footer')
          .btn-red
            el-button(@click='deleteCurrentComunication') Sterge Comunicat
          .btn-cancel
            el-button(type='primary' @click='dialogFormDeleteVisible = false' class="text-bold") Renunta

      .buttons-wrapper
        .buttons-left
          el-button-group.select-group
            el-button(type='primary' @click="toggleSelection(comunications)").select-all Selecteaza tot
            el-button(type='primary' @click="toggleSelection()").deselect-all Deselecteaza tot
          el-button(@click='deleteSelected()' style='margin-left: 2%').delete-selections Sterge selectate
        .buttons-right
          el-button(@click="cleanComunicationForm").add-btn Adauga Comunicat
      el-table(ref='multipleTable' :data='this.comunications' style='width: 100%' @row-click='handleRowChange' @selection-change='handleSelectionChange')
        el-table-column(type='selection' width= '50')
        el-table-column(width='10')
        el-table-column(property="title" label='Titlu')
          template(scope='scope')
            .title-link(@click='handleEditBtnClick') {{ scope.row.title }}
        el-table-column(property='number_of_attachments' label='Atasamente')
        el-table-column(property='date' label='Data')
          template(scope='scope')
            span {{ moment(scope.row.date).format('DD/MM/YYYY') }}
        el-table-column(property='address' label='Adresa de Contact')
        el-table-column(property='sender' label='Expeditor')
        el-table-column(property='group' label='Grup')

        el-table-column(label="Actiuni" width='150')
          .action-buttons
            p.edit-btn(@click='handleEditBtnClick') Editeaza
            .divider
            p.delete-btn(@click='handleDeleteBtnClick') Sterge
      el-pagination(layout="prev, pager, next"
                    :total="totalCount"
                    :page-size="1"
                    :current-page="currentPage"
                    @next-click="handlePageClick"
                    @prev-click="handlePageClick"
                    @current-change="handlePageClick")

</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import { communicationSenders } from '../../utils/communicationsUtils'
import { communicationGroups } from '../../utils/communicationUtils';

export default {
  name: 'ComunicationsView',
  components: {},
  data() {
    return {
      crudOperation: false,
      labelPosition: 'top',
      formComunication: {
        id: null,
        date: '',
        title: '',
        address: '',
        sender: '',
        group: 'FSLI',
        number_of_attachments: null,
        uploadedAttachments: []
      },
      multipleSelection: [],
      attachments: [],
      attachmentsToDelete: [],
      dialogFormVisible: false,
      dialogFormUpdateVisible: false,
      dialogFormDeleteVisible: false,
      formLabelWidth: '120px',
      loading: false,
      communicationSenders: communicationSenders,
      communicationGroups: communicationGroups
    };
  },

  computed: {
    ...mapGetters({
      comunications: 'comunicationsModule/comunications',
      currentPage: 'comunicationsModule/currentPage',
      totalCount: 'comunicationsModule/totalCount'
    }),

    attachmentsForUpdate() {
      return this.formComunication
                 .uploadedAttachments
                 .concat(this.attachments)
                 .filter(item => !this.attachmentsToDelete.includes(item.id));
    },
  },

  created() {
    this.updateCurrentPage(1);
    this.getComunications();
  },

  methods: {
     ...mapActions({
      createComunication: 'comunicationsModule/createComunication',
      updateComunication: 'comunicationsModule/updateComunication',
      deleteComunication: 'comunicationsModule/deleteComunication',
      getComunications:  'comunicationsModule/getComunications'

    }),

    ...mapMutations('comunicationsModule', [
      'updateCurrentPage'
    ]),

    deleteSelected(){
      var result = this.deleteComunication(this.multipleSelection.map(a => a.id));
    },

    cleanComunicationForm(){
      this.formComunication.date = '';
      this.formComunication.title = '';
      this.formComunication.address = '';
      this.formComunication.sender = '';
      this.formComunication.group = 'FSLI';
      this.formComunication.number_of_attachments = null;
      this.crudOperation = true,
      this.dialogFormVisible = true;
    },

    handlePageClick(page) {
      this.updateCurrentPage(page);
      this.getComunications();
    },

    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row, true);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    handleEditBtnClick(){
      this.crudOperation = true;
      this.dialogFormUpdateVisible = true;
    },

    handleDeleteBtnClick(){
      this.dialogFormDeleteVisible = true;
      this.crudOperation = true;
    },

    async addComunication(){
      const formData = new FormData();

      Object.keys(this.formComunication)
            .forEach((item) => {
              formData.append(`communication[${item}]`, this.formComunication[item]);
            });

      Object.keys(this.attachments)
            .forEach((item) => {
              formData.append('communication[attachments][]', this.attachments[item].raw);
            });

      this.loading = true;
      var result = await this.createComunication(formData);
      if (result) {
        this.dialogFormVisible = false;
        this.attachments = [];
        this.crudOperation = false;
      }
      this.loading = false;
    },

    deleteCurrentComunication(){
      var result = this.deleteComunication(this.formComunication.id);
      this.dialogFormDeleteVisible = false;
      this.crudOperation = false;
    },

    handleRowChange(val){
      if (this.crudOperation == true){
        this.formComunication.id = val.id;
        this.formComunication.date = val.date;
        this.formComunication.title = val.title;
        this.formComunication.address = val.address;
        this.formComunication.sender = val.sender;
        this.formComunication.group = val.group;
        this.formComunication.uploadedAttachments = val.attachments;
      }
    },

    async updateCurrentComunication(){
      const formData = new FormData();

      Object.keys(this.formComunication)
            .forEach((item) => {
              formData.append(`communication[${item}]`, this.formComunication[item]);
            });
      this.attachments.forEach((item) => {
        formData.append('communication[attachments][]', item.raw);
      });
      this.attachmentsToDelete.forEach((item) => {
        formData.append('communication[attachments_to_delete][]', item);
      });

      this.loading = true;
      var result = await this.updateComunication(formData);
      if (result) {
        this.dialogFormUpdateVisible = false;
        this.attachments = [];
        this.attachmentsToDelete = [];
        this.crudOperation = false;
      }
      this.loading = false;
    },

    handleClose() {
      this.formComunication.uploadedAttachments = [];
      this.attachments = [];
      this.attachmentsToDelete = [];
    },

    handleRemove(file) {
      this.attachments = this.attachments.filter(item => item.uid !== file.uid);
    },

    attachFiles(file) {
      this.attachments.push(file);
    },

    handleExceed(files, fileList) {
      this.$message.warning(`The limit is 3, you selected ${files.length} files this time, add up to ${files.length + fileList.length} totally`);
    },

    beforeRemove(file, fileList) {
      return this.$confirm(`Cancel the transfert of ${ file.name } ?`);
    },

    handleRemoveUpdate(file) {
      if (file.id) {
        this.attachmentsToDelete.push(file.id);
      } else {
        this.handleRemove(file);
      }
    },

    handlePreview(file) {
      if (file.id) {
        const link = document.createElement('a');
        link.href = file.url;
        link.download = file.name;
        link.click();
      }
    }
  },
};

</script>

<style scoped lang="scss">
  .comunications {
    width: 100%;
    margin: 0px 68px 51px 68px;

    .comunications-wrapper {
      background-color: white;
      padding: 15px 15px 29px;
      height: auto;
      width: 1214px;
    }

    .comunication-title-date {
      display: flex;
      justify-content: space-between;
    }

    .buttons-wrapper {
      width:100%;
      margin-top: 10px;
      margin-bottom: 60px;
      text-align: center;
      margin-left: 1%;
    }

    .select-all{
      color: #EDC731;
      font-family: PTSans;
      font-size: 14px;
      font-weight: 400;
      text-align: left
      width 50;
      height: 100%;
    }

    .delete-selections {
      border: 1px solid #A2A7B0;
      border-radius: 4px;
      height: 36px;
      float: left;
      display: inline-block;
      color: #A2A7B0;
      font-size: 14px;
      font-weight: 400;
      text-align: left;
    }

    .deselect-all{
      color: #C0C4CC;
      font-size: 14px;
      font-weight: 400;
      width: 50%;
      height: 100%;
      text-align: left;
    }

    .title {
      color: #02386D;
      font-family: 'PTSans-Bold';
      font-size: 35px;
      font-weight: 400;
      line-height: 42px;
      text-align: left;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .select-group {
      border-radius: 4px;
      width: 275px;
      height: 36px;
      display: inline-block;
      float: left;
    }

    .buttons-right{
      float: right;
      margin-right: 4%;
    }

    .add-btn {
      background-color: #EDC731;
      border-radius: 4px;
      width: 188px;
      height: 40px;
      color: #FFFFFF;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      text-align: center;
    }

    .import-btn {
      border: 1px solid #EDC731;
      border-radius: 4px;
      width: 151px;
      height: 40px;
      color: #EDC731;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      text-align: center;
    }

  .edit-btn{
    color: #1E7CD7;
    font-size: 14px;
    font-weight: 400;
    float: left;
    cursor: pointer;
  }

  .delete-btn{
    color: #FA5555;
    font-size: 14px;
    font-weight: 400;
    float: right;
    cursor: pointer;
  }
}
</style>

<template lang="pug">
  .offers
    p.title Oferte
    .offers-wrapper
      el-dialog.offers-dialog(title='Adauga Oferta' :visible.sync='dialogFormVisible')
        el-form(:label-position="labelPosition" label-width="100px" :model="formOffer")
          .offer-title-date
            el-form-item(label='Data' :label-width='formLabelWidth').form-date
              el-date-picker(type='date' placeholder='ZZ/LL/AAAA' v-model='formOffer.date')
            el-form-item(label='Titlu' :label-width='formLabelWidth').form-title
              el-input(v-model='formOffer.title' placeholder="Titlu oferta" autocomplete='off')
          el-form-item(label='Tip oferta' :label-width='formLabelWidth')
            el-radio-group(v-model="offerType")
              el-radio(:label="'voucher'") Voucher
              el-radio(:label="'qr_code'") Cod QR
              el-radio(:label="'link'") Oferta Online
          el-form-item(label='Incarcati Codul QR' :label-width='formLabelWidth' class='checked-inputs' v-if="qr_code_offer() == true")
            el-upload.upload-demo.upload-btn(
              action="#"
              :multiple="false"
              :auto-upload="false"
              :on-change="attachQrFiles"
              accept="image/png, image/jpeg, image/jpg")
              el-button Upload
          el-form-item.form-title(label='Introduceti codul aferent voucher-ului' :label-width='formLabelWidth' class='checked-inputs' v-if="voucher() == true")
            el-input(v-model='formOffer.voucher_code' placeholder="Cod Voucher" autocomplete='off')
          el-form-item.form-title(label='Link catre pagina web' :label-width='formLabelWidth' class='checked-inputs' v-if="link() == true")
            el-input(v-model='formOffer.link' placeholder="Link catre pagina web" autocomplete='off')
          el-form-item(label='Descriere' :label-width='formLabelWidth')
            el-input(type='textarea' placeholder="Descriere oferta" v-model='formOffer.description')
          el-form-item(label='Imagini de prezentare (jpeg, jpg, png)' :label-width='formLabelWidth')
            el-upload.upload-demo.upload-btn(
              action="#"
              :multiple="true"
              :auto-upload="false"
              :file-list="images"
              :on-change="attachFiles"
              :before-remove="handleRemove"
              accept="image/png, image/jpeg, image/jpg")
              el-button Upload
        .dialog-footer(slot='footer')
          .btn-cancel
            el-button(type='primary' @click='dialogFormVisible = false' class="text-bold") Renunta
          .btn-add
            el-button(@click='addOffer' :loading="loading") Adauga Oferta
      el-dialog.offers-dialog(title='Detalii Oferta' :visible.sync='dialogFormUpdateVisible' @close="handleClose")
        el-form(:label-position="labelPosition" label-width="100px" :model="formOffer")
          .offer-title-date
            el-form-item(label='Data' :label-width='formLabelWidth').form-date
              el-date-picker(type='date' placeholder='ZZ/LL/AAAA' format='dd/mm/yyyy' v-model='formOffer.date')
            el-form-item(label='Titlu' :label-width='formLabelWidth').form-title
              el-input(v-model='formOffer.title' placeholder="Titlu oferta" autocomplete='off')
          el-form-item(label='Tip oferta' :label-width='formLabelWidth')
            el-radio-group(v-model="offerType")
              el-radio(:label="'voucher'") Voucher
              el-radio(:label="'qr_code'") Cod QR
              el-radio(:label="'link'") Oferta Online
          el-form-item(label='Incarcati Codul QR' :label-width='formLabelWidth' class='checked-inputs' v-if="qr_code_offer() == true")
            el-upload.upload-demo.upload-btn(
              action="#"
              :multiple="false"
              :auto-upload="false"
              :on-change="attachQrFiles"
              accept="image/png, image/jpeg, image/jpg")
              el-button Upload
          el-form-item.form-title(label='Introduceti codul aferent voucher-ului' :label-width='formLabelWidth' class='checked-inputs'  v-if="voucher() == true")
            el-input(v-model='formOffer.voucher_code' placeholder="Cod Voucher" autocomplete='off')
          el-form-item.form-title(label='Link catre pagina web' :label-width='formLabelWidth' class='checked-inputs' v-if="link() == true")
            el-input(v-model='formOffer.link' placeholder="Link catre pagina web" autocomplete='off')
          el-form-item(label='Descriere' :label-width='formLabelWidth')
            el-input(type='textarea' placeholder="Descriere oferta" v-model='formOffer.description')
          el-form-item(label='Imagini de prezentare (jpeg, jpg, png)' :label-width='formLabelWidth')
            el-upload.upload-demo.upload-btn(
              action="#"
              :multiple="true"
              :auto-upload="false"
              :file-list="imagesForUpdate"
              :on-change="attachFiles"
              :before-remove="handleRemoveUpdate"
              :on-preview="handlePreview"
              accept="image/png, image/jpeg, image/jpg")
              el-button Upload
        .dialog-footer(slot='footer')
          .btn-cancel
            el-button(type='primary' @click='dialogFormUpdateVisible = false' class="text-bold") Renunta
          .btn-add
            el-button(@click='updateCurrentOffer' :loading="loading") Modifica Oferta
      el-dialog.users-dialog(title='Sterge Oferta' :visible.sync='dialogFormDeleteVisible')
        .delete-text
          p Urmatoarea actiune va sterge toate datele despre oferta.
          p Sunteti sigur ca doriti sa continuati?
        .dialog-footer(slot='footer')
          .btn-red
            el-button(@click='deleteCurrentOffer') Sterge Oferta
          .btn-cancel
            el-button(type='primary' @click='dialogFormDeleteVisible = false' class="text-bold") Renunta

      .buttons-wrapper
        .buttons-left
          el-button-group.select-group
            el-button(type='primary' @click="toggleSelection(offers)").select-all Selecteaza tot
            el-button(type='primary' @click="toggleSelection()").deselect-all Deselecteaza tot
          el-button(@click='deleteSelected()' style='margin-left: 2%').delete-selections Sterge selectate
        .buttons-right
          el-button(@click="cleanOfferForm").add-btn Adauga Oferta
      el-table(ref='multipleTable' :data='offers' style='width: 100%' @row-click='handleRowChange' @selection-change='handleSelectionChange')
        el-table-column(type='selection' width='50')
        el-table-column(width='10')
        el-table-column(property='date' label='Data' width='200')
          template(scope='scope')
            span {{ moment(scope.row.date).format('DD/MM/YYYY') }}
        el-table-column(property="title" label='Titlu' width='200')
          template(scope='scope')
            .title-link(@click='handleEditBtnClick') {{ scope.row.title }}
        el-table-column(property='offer_type' label='Tip Oferta' width='200')
        el-table-column(property='description' label='Descriere' width='200')
        el-table-column(property='visits' label='Numar de Accesari' width='200')
        el-table-column(label="Actiuni" width='150')
          .action-buttons
            p.edit-btn(@click='handleEditBtnClick') Editeaza
            .divider
            p.delete-btn(@click='handleDeleteBtnClick') Sterge
      el-pagination(layout="prev, pager, next"
                    :total="totalCount"
                    :page-size="1"
                    :current-page="currentPage"
                    @next-click="handlePageClick"
                    @prev-click="handlePageClick"
                    @current-change="handlePageClick")

</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';

export default {
  name: 'OffersView',
  components: {},
  data() {
    return {
      crudOperation: false,
      labelPosition: 'top',
      formOffer: {
        id: null,
        date: '',
        title: '',
        description: '',
        voucher_code: '',
        link: '',
        uploadedImages: []
      },
      multipleSelection: [],
      offerType: 'voucher',
      qr_code: null,
      images: [],
      imagesToDelete: [],
      dialogFormVisible: false,
      dialogFormUpdateVisible: false,
      dialogFormDeleteVisible: false,
      formLabelWidth: '120px',
      loading: false,
    };
  },

  computed: {
    ...mapGetters({
      offers: 'offersModule/offers',
      currentPage: 'offersModule/currentPage',
      totalCount: 'offersModule/totalCount'
    }),

    imagesForUpdate() {
      return this.formOffer.uploadedImages.concat(this.images).filter(item => !this.imagesToDelete.includes(item.id));
    },
  },

  created() {
    this.updateCurrentPage(1);
    this.getOffers();
  },

  methods: {
     ...mapActions({
      createOffer: 'offersModule/createOffer',
      updateOffer: 'offersModule/updateOffer',
      getOffers:  'offersModule/getOffers',
      deleteOffer: 'offersModule/deleteOffer'
    }),

    ...mapMutations('offersModule', [
      'updateCurrentPage'
    ]),

    deleteSelected() {
      var result = this.deleteOffer(this.multipleSelection.map(a => a.id));
    },

    cleanOfferForm() {
      this.formOffer.date = '';
      this.formOffer.title = '';
      this.formOffer.description = '';
      this.formOffer.voucher_code = '';
      this.formOffer.attachments = '';
      this.formOffer.link = '';
      this.images = [];
      this.crudOperation = true,
      this.dialogFormVisible = true;
      this.qr_code = null;
      this.offerType = 'voucher';
    },

    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row, true);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    voucher() {
      return(this.offerType == 'voucher');
    },

    qr_code_offer() {
      return(this.offerType == 'qr_code');
    },

    link() {
      return(this.offerType == 'link');
    },

    handlePageClick(page) {
      this.updateCurrentPage(page);
      this.getOffers();
    },

    handleEditBtnClick() {
      this.crudOperation = true;
      this.dialogFormUpdateVisible = true;
    },

    handleDeleteBtnClick() {
      this.dialogFormDeleteVisible = true;
      this.crudOperation = true;
    },

    async addOffer() {
      const formData = new FormData();

      Object.keys(this.formOffer)
            .forEach((item) => {
              formData.append(`offer[${item}]`, this.formOffer[item]);
            });
      Object.keys(this.images)
            .forEach((item) => {
              formData.append('offer[images][]', this.images[item].raw);
            });
      if (this.qr_code !== null){
        formData.append('offer[qr_code]', this.qr_code.raw);
      }
      formData.append('offer[offer_type]', this.offerType);

      this.loading = true;
      var result = await this.createOffer(formData);
      if (result) {
        this.dialogFormVisible = false;
        this.images = [];
        this.qr_code = null;
        this.crudOperation = false;
      }
      this.loading = false;
    },

    deleteCurrentOffer() {
      var result = this.deleteOffer(this.formOffer.id);
      this.dialogFormDeleteVisible = false;
      this.crudOperation = false;
    },

    handleRowChange(val) {
      if (this.crudOperation == true){
        this.formOffer.id = val.id;
        this.formOffer.date = val.created_at;
        this.formOffer.title = val.title;
        this.offerType = val.offer_type;
        this.formOffer.description = val.description;
        this.formOffer.voucher_code = val.voucher_code;
        this.formOffer.link = val.link;
        this.formOffer.uploadedImages = val.images;
      }
    },

    async updateCurrentOffer() {
       const formData = new FormData();

      Object.keys(this.formOffer)
            .forEach((item) => {
              formData.append(`offer[${item}]`, this.formOffer[item]);
            });
      this.images.forEach((item) => {
        formData.append('offer[images][]', item.raw);
      });
      this.imagesToDelete.forEach((item) => {
        formData.append('offer[images_to_delete][]', item);
      });
      if (this.qr_code !== null){
        formData.append('offer[qr_code]', this.qr_code.raw);
      }
      formData.append('offer[offer_type]', this.offerType);

      this.loading = true;
      var result = await this.updateOffer(formData);
      if (result) {
        this.dialogFormUpdateVisible = false;
        this.imagesToDelete = [];
        this.images = [];
        this.qr_code = null;
        this.crudOperation = false;
      }
      this.loading = false;
    },

    handleClose() {
      this.formOffer.uploadedImages = [];
      this.images = [];
      this.imagesToDelete = [];
    },

    handleRemove(file) {
      this.images = this.images.filter(item => item.uid !== file.uid);
    },

    attachFiles(file) {
      this.images.push(file);
    },

    attachQrFiles(file) {
      this.qr_code = file;
    },

    handleExceed(files, fileList) {
      this.$message.warning(`The limit is 3, you selected ${files.length} files this time, add up to ${files.length + fileList.length} totally`);
    },

    beforeRemove(file, fileList) {
      return this.$confirm(`Cancel the transfert of ${ file.name } ?`);
    },

    handleRemoveUpdate(file) {
      if (file.id) {
        this.imagesToDelete.push(file.id);
      } else {
        this.handleRemove(file);
      }
    },

    handlePreview(file) {
      if (file.id) {
        const link = document.createElement('a');
        link.href = file.url;
        link.download = file.name;
        link.click();
      }
    }
  },
};

</script>

<style scoped lang="scss">
  .offers {
    width: 100%;
    margin: 0px 68px 51px 68px;

    .offers-wrapper {
      background-color: white;
      padding: 15px 15px 29px;
      height: auto;
      width: 1214px;
    }

    .buttons-wrapper {
      width:100%;
      margin-top: 10px;
      margin-bottom: 60px;
      text-align: center;
      margin-left: 1%;
    }

    .select-all{
      color: #EDC731;
      font-family: PTSans;
      font-size: 14px;
      font-weight: 400;
      text-align: left
      width 50;
      height: 100%;
    }

    .delete-selections {
      border: 1px solid #A2A7B0;
      border-radius: 4px;
      height: 36px;
      float: left;
      display: inline-block;
      color: #A2A7B0;
      font-size: 14px;
      font-weight: 400;
      text-align: left;
    }

    .deselect-all{
      color: #C0C4CC;
      font-size: 14px;
      font-weight: 400;
      width: 50%;
      height: 100%;
      text-align: left;
    }

    .title {
      color: #02386D;
      font-family: 'PTSans-Bold';
      font-size: 35px;
      font-weight: 400;
      line-height: 42px;
      text-align: left;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .select-group {
      border-radius: 4px;
      width: 275px;
      height: 36px;
      display: inline-block;
      float: left;
    }

    .buttons-right{
      float: right;
      margin-right: 4%;
    }

    .add-btn {
      background-color: #EDC731;
      border-radius: 4px;
      width: 188px;
      height: 40px;
      color: #FFFFFF;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      text-align: center;
    }

    .import-btn {
      border: 1px solid #EDC731;
      border-radius: 4px;
      width: 151px;
      height: 40px;
      color: #EDC731;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      text-align: center;
    }

  .edit-btn{
    color: #1E7CD7;
    font-size: 14px;
    font-weight: 400;
    float: left;
    cursor: pointer;
  }

  .delete-btn{
    color: #FA5555;
    font-size: 14px;
    font-weight: 400;
    float: right;
    cursor: pointer;
  }
}
</style>

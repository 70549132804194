<template lang="pug">
  .activities
    p.title Activitati
    .activities-wrapper
      el-dialog.activities-dialog(title='Adauga Activitate' :visible.sync='dialogFormVisible')
        el-form(:label-position="labelPosition" label-width="100px" :model="formActivity")
          .activity-title-date
            el-form-item(label='Titlu' :label-width='formLabelWidth').form-title
              el-input(v-model='formActivity.title' placeholder="Titlu activitate" autocomplete='off')
            el-form-item(label='Data' :label-width='formLabelWidth').form-date
              el-date-picker(type='date' placeholder='ZZ/LL/AAAA' v-model='formActivity.date')
          el-form-item(label='Descriere' :label-width='formLabelWidth')
            el-input(type='textarea' placeholder="Descriere activitate" v-model='formActivity.description')
          el-form-item(label='Atasamente (jpeg, jpg, png)' :label-width='formLabelWidth')
            el-upload.upload-demo.upload-btn(
              action="#"
              :multiple="true"
              :auto-upload="false"
              :file-list="images"
              :on-change="attachFiles"
              :before-remove="handleRemove"
              accept="image/png, image/jpeg, image/jpg")
              el-button Upload
        .dialog-footer(slot='footer')
          .btn-cancel
            el-button(type='primary' @click='dialogFormVisible = false' class="text-bold") Renunta
          .btn-add
            el-button(@click='addActivity' :loading="loading") Adauga Activitate

      el-dialog.activities-dialog(title='Detalii Activitate' :visible.sync='dialogFormUpdateVisible' @close="handleClose")
        el-form(:label-position="labelPosition" label-width="100px" :model="formActivity")
          .activity-title-date
            el-form-item(label='Titlu' :label-width='formLabelWidth').form-title
              el-input(v-model='formActivity.title' placeholder="Titlu activitate" autocomplete='off')
            el-form-item(label='Data' :label-width='formLabelWidth').form-date
              el-date-picker(type='date' placeholder='ZZ/LL/AAAA' v-model='formActivity.date')
          el-form-item(label='Descriere' :label-width='formLabelWidth')
            el-input(type='textarea' placeholder="Descriere activitate" v-model='formActivity.description')
          el-form-item(label='Atasamente (jpeg, jpg, png)' :label-width='formLabelWidth')
            el-upload.upload-demo.upload-btn(
              action="#"
              :multiple="true"
              :auto-upload="false"
              :file-list="imagesForUpdate"
              :on-change="attachFiles"
              :before-remove="handleRemoveUpdate"
              :on-preview="handlePreview"
              accept="image/png, image/jpeg, image/jpg")
              el-button Upload

        .dialog-footer(slot='footer')
          .btn-cancel
            el-button(type='primary' @click='dialogFormUpdateVisible = false' class="text-bold") Renunta
          .btn-add
            el-button(@click='updateCurrentActivity' :loading="loading") Salveaza
      el-dialog(title='Sterge Activitate' :visible.sync='dialogFormDeleteVisible').users-dialog
        .delete-text
          p Urmatoarea actiune va sterge toate datele despre activitate.
          p Sunteti sigur ca doriti sa continuati?
        .dialog-footer(slot='footer')
          .btn-red
            el-button(@click='deleteCurrentActivity') Sterge Activitate
          .btn-cancel
            el-button(type='primary' @click='dialogFormDeleteVisible = false' class="text-bold") Renunta

      .buttons-wrapper
        .buttons-left
          el-button-group.select-group
            el-button(type='primary' @click="toggleSelection(activities)").select-all Selecteaza tot
            el-button(type='primary' @click="toggleSelection()").deselect-all Deselecteaza tot
          el-button(@click='deleteSelected()' style='margin-left: 2%').delete-selections Sterge selectate
        .buttons-right
          el-button(@click="cleanActivityForm").add-btn Adauga Activitate
      el-table(ref='multipleTable' :data='this.activities' style='width: 100%' @row-click='handleRowChange' @selection-change='handleSelectionChange')
        el-table-column(type='selection' width='50')
        el-table-column(width='10')
        el-table-column(property='date' label='Data' width='200')
          template(scope='scope')
            span {{ moment(scope.row.date).format('DD/MM/YYYY') }}
        el-table-column(property="title" label='Titlu' width='200')
          template(scope='scope')
            .title-link(@click='handleEditBtnClick') {{ scope.row.title }}
        el-table-column(property='description' label='Descriere' width='200')
          template(scope='scope')
            span {{ scope.row.description }}
        el-table-column(property='number_of_images' label='Atasamente' width='320')

        el-table-column(label="Actiuni" width='150')
          .action-buttons
            p.edit-btn(@click='handleEditBtnClick') Editeaza
            .divider
            p.delete-btn(@click='handleDeleteBtnClick') Sterge
      el-pagination(layout="prev, pager, next"
                    :total="totalCount"
                    :page-size="1"
                    :current-page="currentPage"
                    @next-click="handlePageClick"
                    @prev-click="handlePageClick"
                    @current-change="handlePageClick")

</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';

export default {
  name: 'ActivitiesView',
  components: {},
  data() {
    return {
      crudOperation: false,
      labelPosition: 'top',
      formActivity: {
        id: null,
        date: '',
        title: '',
        description: '',
        uploadedImages: []
      },
      multipleSelection: [],
      images: [],
      imagesToDelete: [],
      dialogFormVisible: false,
      dialogFormUpdateVisible: false,
      dialogFormDeleteVisible: false,
      formLabelWidth: '120px',
      loading: false,
    };
  },

  computed: {
    ...mapGetters({
      activities: 'activitiesModule/activities',
      currentPage: 'activitiesModule/currentPage',
      totalCount: 'activitiesModule/totalCount'
    }),

    imagesForUpdate() {
      return this.formActivity.uploadedImages.concat(this.images).filter(item => !this.imagesToDelete.includes(item.id));
    },
  },

  created() {
    this.updateCurrentPage(1);
    this.getActivities();
  },

  methods: {
     ...mapActions({
      createActivity: 'activitiesModule/createActivity',
      updateActivity: 'activitiesModule/updateActivity',
      getActivities:  'activitiesModule/getActivities',
      deleteActivity: 'activitiesModule/deleteActivity'
    }),

    ...mapMutations('activitiesModule', [
      'updateCurrentPage'
    ]),

    cleanActivityForm() {
      this.formActivity.date = '';
      this.formActivity.title = '';
      this.formActivity.description = '';
      this.formActivity.attachments = '';
      this.crudOperation = true,
      this.dialogFormVisible = true;
    },

    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row, true);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    deleteSelected() {
      var result = this.deleteActivity(this.multipleSelection.map(a => a.id));
    },

    handlePageClick(page) {
      this.updateCurrentPage(page);
      this.getActivities();
    },

    handleEditBtnClick() {
      this.crudOperation = true;
      this.dialogFormUpdateVisible = true;
    },

    handleDeleteBtnClick() {
      this.dialogFormDeleteVisible = true;
      this.crudOperation = true;
    },

    async addActivity() {
      const formData = new FormData();

      Object.keys(this.formActivity)
            .forEach((item) => {
              formData.append(`activity[${item}]`, this.formActivity[item]);
            });

      Object.keys(this.images)
            .forEach((item) => {
              formData.append('activity[images][]', this.images[item].raw);
            });

      this.loading = true;
      var result = await this.createActivity(formData);

      if (result) {
        this.dialogFormVisible = false;
        this.images = [];
        this.crudOperation = false;
      }
      this.loading = false;
    },

    deleteCurrentActivity() {
      var result = this.deleteActivity(this.formActivity.id);
      this.dialogFormDeleteVisible = false;
      this.crudOperation = false;
    },

    handleRowChange(val) {
      if (this.crudOperation == true) {
        this.formActivity.id = val.id;
        this.formActivity.date = val.date;
        this.formActivity.title = val.title;
        this.formActivity.description = val.description;
        this.formActivity.uploadedImages = val.images;
      }
    },

    async updateCurrentActivity() {
      const formData = new FormData();
      Object.keys(this.formActivity)
            .forEach((item) => {
              formData.append(`activity[${item}]`, this.formActivity[item]);
            });
      this.images.forEach((item) => {
        formData.append('activity[images][]', item.raw);
      });
      this.imagesToDelete.forEach((item) => {
        formData.append('activity[images_to_delete][]', item);
      });

      this.loading = true;
      var result = await this.updateActivity(formData);

      if (result) {
        this.dialogFormUpdateVisible = false;
        this.crudOperation = false;
        this.imagesToDelete = [];
        this.images = [];
      }
      this.loading = false;
    },

    handleClose() {
      this.formActivity.uploadedImages = [];
      this.images = [];
      this.imagesToDelete = [];
    },

    handleRemove(file) {
      this.images = this.images.filter(item => item.uid !== file.uid);
    },

    attachFiles(file) {
      this.images.push(file);
    },

    handleExceed(files, fileList) {
      this.$message.warning(`The limit is 3, you selected ${files.length} files this time, add up to ${files.length + fileList.length} totally`);
    },

    beforeRemove(file, fileList) {
      return this.$confirm(`Cancel the transfert of ${ file.name } ?`);
    },

    handleRemoveUpdate(file) {
      if (file.id) {
        this.imagesToDelete.push(file.id);
      } else {
        this.handleRemove(file);
      }
    },

    handlePreview(file) {
      if (file.id) {
        const link = document.createElement('a');
        link.href = file.url;
        link.download = file.name;
        link.click();
      }
    }
  },
};

</script>

<style scoped lang="scss">
  .activities {
    width: 100%;
    margin: 0px 68px 51px 68px;

    .activities-wrapper {
      background-color: white;
      padding: 15px 15px 29px;
      height: auto;
      width: 1214px;
    }

    .activity-title-date {
      display: flex;
      justify-content: space-between;
    }

    .buttons-wrapper {
      width:100%;
      margin-top: 10px;
      margin-bottom: 60px;
      text-align: center;
      margin-left: 1%;
    }

    .select-all{
      color: #EDC731;
      font-family: PTSans;
      font-size: 14px;
      font-weight: 400;
      text-align: left
      width 50;
      height: 100%;
    }

    .delete-selections {
      border: 1px solid #A2A7B0;
      border-radius: 4px;
      height: 36px;
      float: left;
      display: inline-block;
      color: #A2A7B0;
      font-size: 14px;
      font-weight: 400;
      text-align: left;
    }

    .deselect-all{
      color: #C0C4CC;
      font-size: 14px;
      font-weight: 400;
      width: 50%;
      height: 100%;
      text-align: left;
    }

    .title {
      color: #02386D;
      font-family: 'PTSans-Bold';
      font-size: 35px;
      font-weight: 400;
      line-height: 42px;
      text-align: left;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .select-group {
      border-radius: 4px;
      width: 275px;
      height: 36px;
      display: inline-block;
      float: left;
    }

    .buttons-right{
      float: right;
      margin-right: 4%;
    }

    .add-btn {
      background-color: #EDC731;
      border-radius: 4px;
      width: 188px;
      height: 40px;
      color: #FFFFFF;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      text-align: center;
    }

    .import-btn {
      border: 1px solid #EDC731;
      border-radius: 4px;
      width: 151px;
      height: 40px;
      color: #EDC731;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      text-align: center;
    }

  .edit-btn{
    color: #1E7CD7;
    font-size: 14px;
    font-weight: 400;
    float: left;
    cursor: pointer;
  }

  .delete-btn{
    color: #FA5555;
    font-size: 14px;
    font-weight: 400;
    float: right;
    cursor: pointer;
  }
}
</style>
